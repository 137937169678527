
import Vue from "vue";
export default Vue.extend({
  name: "BlockInfo",
  props: {
    state: {
      type: Boolean,
      default: null
    },
    invalidFeedback: {
      type: String,
      default: "This field is required"
    }
  }
});
