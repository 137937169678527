import Vue from "vue";
import { makeList } from "./makeList";
import { FunnelsService } from "@claimsgate/core";
import { getFirebaseBackend } from "@/authUtils";
import { VariableService } from "@/helpers/ClaimsGate/VariableService";
import { ClaimDataService } from "@/helpers/ClaimsGate/DataService";
import { getUserHelper } from "@/helpers/ClaimsGate/UserHelper";
import { Variable } from "@claimsgate/core-types";
import { InfoModalService } from "../../global/informationModal/InfoModalService";
import { BlockProps } from "@/types";
import { UserVariableBlocksService } from "@/helpers/ClaimsGate/UserVariableBlocksService";

/** Each of the BlockButtonProps used in this component */
interface BlockButtonProps {
  regInput?: Partial<BlockProps.Button>;
  makeModel?: Partial<BlockProps.Button>;
  purchaseDate?: Partial<BlockProps.Button>;
}
const buttons: BlockButtonProps = {
  regInput: { submitButtonDefault: false, text: "Continue", block: false },
  makeModel: { submitButtonDefault: false, text: "Continue", block: false },
  purchaseDate: { submitButtonDefault: false, text: "Continue", block: false },
};
export function getData(state: Vue) {
  return {
    currentView: "regInput" as "regInput" | "makeModel" | "purchaseDate",
    makeList: makeList,
    funnelsService: new FunnelsService(getFirebaseBackend().firestore()),
    variablesService: new VariableService(getFirebaseBackend().firestore()),
    claimDataService: null as ClaimDataService | null,
    funnelId: state.$route.params.funnelId,
    claimId: state.$route.params.claimId,
    userId: null as string,
    userService: getUserHelper(),
    funnelVariables: [] as Array<Variable>,
    infoModalService: new InfoModalService(state.$infoModal),
    store: {
      purchaseDate: null as string,
      vrm: null as string,
      make: null as string,
      model: null as string,
      otherMake: null as string,
    },
    uiState: {
      purchaseDate: null as boolean | null,
      vrm: null as boolean | null,
      make: null as boolean | null,
      model: null as boolean | null,
      otherMake: null as boolean | null,
    },
    invalidFeedbacks: {
      purchaseDate: null as string,
      vrm: null as string,
      make: null as string,
      model: null as string,
      otherMake: null as string,
    },
    BlockButtonInputs: {
      ...UserVariableBlocksService.getButtons(buttons as any),
    } as BlockButtonProps,
  };
}
