
import Vue, { VueConstructor } from "vue";
import { getProps, getData, BlockVehicleSplitTestInstance, computed, methods } from "./BlockVehicleSplitTestInstance";
import { Fragment } from "vue-frag";

// Components
import BlockPadding from "@/components/shared/blocks/padding.vue";
import BlockCarweb from "@/components/shared/blocks/utility/vehicle.vue";
import BlockMotorSpecs from "@/components/shared/blocks/motorSpecs/motorSpecs.vue";
import { mappedComputed } from "./computed";
import { mounted } from "./mounted";

export default (Vue as VueConstructor<Vue>).extend({
  name: "BlocKVehicleSplitTest",
  components: { Fragment, BlockCarweb, BlockMotorSpecs, BlockPadding },
  props: { ...getProps() },

  methods: {
    fakeMethod(): string {
      return methods.fakeMethod();
    },
  },
  computed: {
    ...mappedComputed(),
    aComputed(): string {
      return computed.aComputed(this as BlockVehicleSplitTestInstance);
    },
  },
  data() {
    return { ...getData(this) };
  },

  async mounted(): Promise<void> {
    mounted(this as BlockVehicleSplitTestInstance);
  },
});
