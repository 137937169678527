
import Vue from "vue";
import BlockInputWrapper from "@/components/shared/blocks/inputwrapper.vue";
import { inputProps } from "@/helpers/ClaimsGate/blocks/inputProps";
import { Props } from "@/types";
import { themesComputed } from "@/state/helpers";
const inputPropsValue = inputProps({
  answerType: "string",
  extraProps: ["textRows", "placeholder"],
  placeholder: "Type here",
});
export default Vue.extend({
  name: "BlockTextArea",
  components: {
    BlockInputWrapper,
  },
  props: {
    ...(inputPropsValue as Props.BlockTextArea),
  },
  computed: {
    ...themesComputed,
  },
  data() {
    return {
      newAnswer: "",
    };
  },
  mounted() {
    if (this.answer && this.answer?.length > 0) {
      this.newAnswer = this.answer;
    }
  },
  watch: {
    newAnswer() {
      this.$emit("update:state", null);
      this.$emit("update:answer", this.newAnswer);
    },
  },
});
