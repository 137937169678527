
import Vue, { PropType } from "vue";
import HeadingContent from "@/components/shared/styleWrappers/headingContent/headingContent.vue";
import { GreyTextColour } from "../types";
import { faSquare, IconDefinition } from "@fortawesome/pro-regular-svg-icons";

export default Vue.extend({
  name: "CardHeader",
  components: {
    HeadingContent,
  },
  data() {
    return {
      faSquare,
    };
  },

  props: {
    heading: {
      type: String,
      required: true,
    },
    supportingText: {
      type: String,
      required: true,
    },

    hideSupportingText: {
      type: Boolean,
      default: false,
    },

    supportingTextClass: {
      type: String as PropType<GreyTextColour>,
      default: "text-grey-700" as GreyTextColour,
    },
    supportingBadge: {
      type: String,
      required: false,
    },
    supportingBadgeClass: {
      type: String,
      default: "bg-primary-50 text-primary-700",
    },
    headingTextClass: {
      type: String as PropType<GreyTextColour>,
      default: "text-grey-900" as GreyTextColour,
    },
    headingMarginBottom: {
      required: false,
      type: String,
      default: "mb-1",
    },
    closeHandler: {
      required: false,
      type: Function as PropType<() => void>,
    },
    icon: {
      required: false,
      type: [String as PropType<string>, Object as PropType<IconDefinition>] as PropType<string | IconDefinition>,
    },
  },
});
