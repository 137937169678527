
import Vue from "vue";

import BlockInput from "@/components/shared/blocks/input.vue";
import BlockDropdown from "@/components/shared/blocks/dropdown.vue";
import BlockPadding from "@/components/shared/blocks/padding.vue";
import { BlockCompanyInstance, getData, getProps, mappedComputed, methods } from "./BlockCompany";

export default Vue.extend({
  name: "BlockCompany",
  components: { BlockInput, BlockDropdown, BlockPadding },
  computed: {
    ...mappedComputed(),
    fake(): string {
      return this.fake;
    },
  },
  data() {
    return {
      ...getData(this),
    };
  },
  methods: {
    async searchCompany(): Promise<void> {
      await methods.searchCompany(this as BlockCompanyInstance);
    },
    async validations(): Promise<boolean> {
      return methods.validations(this as BlockCompanyInstance);
    },
    async computes(): Promise<boolean> {
      return methods.computes(this as BlockCompanyInstance);
    },
    toggleManualEntry() {
      methods.toggleManualEntry(this as BlockCompanyInstance);
    },
    toggleCompanySearch() {
      methods.toggleCompanySearch(this as BlockCompanyInstance);
    },
  },
  props: {
    ...getProps(),
  },
  watch: {
    /** Propagate padding to the child components */
    padding: {
      handler() {
        Object.keys(this.BlockInputs).forEach((key) => {
          this.BlockInputs[key].padding = this.padding;
        });
      },
    },
    label: {
      handler() {
        this.BlockInputs.companyName.label = this.label as string;
      },
      immediate: true,
    },
  },
  async mounted() {
    await methods.mounted(this as BlockCompanyInstance);
  },
});
