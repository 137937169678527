
import CardHeader from "@/components/shared/styleWrappers/cardHeader/cardHeader.vue";
import TrackSection from "@/components/claimant/track/trackSection/trackSection.vue";

import { mapGetters } from "vuex";
import { CreateTicketRequest, User } from "@claimsgate/core-types";
import Vue, { PropOptions } from "vue";

import { onCallCrmGateway, onCallGateway } from "@/helpers/ClaimsGate/onCallGateway";

import { InfoModalService } from "@/components/shared/global/informationModal/InfoModalService";

import { UserDataService } from "@/helpers/ClaimsGate/DataService";
export default Vue.extend({
  props: {
    claimId: {
      type: String,
      required: true,
    },
    user: {
      type: Object,
      required: true,
    } as PropOptions<User>,
    showTrackCard: {
      type: Boolean,
      required: false,
      default: true,
    },
    funnelId: {
      type: String,
      required: false,
    },
    userId: {
      type: String,
      required: true,
    },
    workspaceId: {
      type: String,
      required: true,
    },
    funnelAllowClaimantTicketCreation: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  components: {
    CardHeader,
    TrackSection,
  },
  computed: {
    ...mapGetters({
      workspaceName: "navigation/getWorkspaceName",
    }),
    isEmailPrePopulated(): boolean {
      return this.user?.email?.length > 0;
    },
    isNamePrePopulated(): boolean {
      return this.user?.firstName?.length > 0 && this.user?.lastName?.length > 0;
    },
    isEmailValid(): boolean {
      const regex =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

      return regex.test(this.email);
    },
  },
  data() {
    return {
      email: this.user?.email || "",
      firstName: this.user.firstName || "",
      lastName: this.user.lastName || "",
      subject: "",
      message: "",

      uiToggles: {
        inProgress: false,
        //emailInvalid: false,
        //messageFailed: false,
      },
      uiStates: {
        email: null,
        message: null,
        firstName: null,
        lastName: null,
        form: null,
      },
      uiInvalidFeedback: {
        email: "",
        message: "",
        firstName: "",
        lastName: "",
        form: "",
      },
      infoModalService: new InfoModalService(this.$infoModal),
      userDataService: null as UserDataService | null,
    };
  },
  methods: {
    async onSubmit() {
      this.resetState();
      this.uiToggles.inProgress = true;

      if (!this.firstName || this.firstName.length === 0) {
        this.uiStates.firstName = false;
        this.uiInvalidFeedback.firstName = "Please enter a first name.";
        this.uiToggles.inProgress = false;
        return;
      }

      if (!this.lastName || this.lastName.length === 0) {
        this.uiStates.lastName = false;
        this.uiInvalidFeedback.lastName = "Please enter a last name.";
        this.uiToggles.inProgress = false;
        return;
      }

      if (!this.message || this.message?.length === 0) {
        this.uiStates.message = false;
        this.uiInvalidFeedback.message = "Please provide a message.";
        this.uiToggles.inProgress = false;
        return;
      }

      try {
        if (this.isEmailPrePopulated && this.funnelAllowClaimantTicketCreation) {
          await this.createTicket();
        } else {
          await this.submitContactForm();
        }
      } catch (error) {
        console.error("Error sending message:", error);
        this.uiStates.message = false;
        this.uiInvalidFeedback.message = "Failed to send message. Please try again.";
      }
      this.uiToggles.inProgress = false;
      this.$bvModal.hide("contactModal");
    },
    async submitContactForm() {
      if (!this.isEmailValid && !this.isEmailPrePopulated) {
        this.uiStates.email = false;
        this.uiInvalidFeedback.email = "Please enter a valid email address.";
        this.uiToggles.inProgress = false;

        return;
      }
      const result = await this.emailWorkspaceContactFunction();

      if (result.data.error || !result.data.sent) {
        this.uiStates.message = false;
        this.uiInvalidFeedback.message = "Failed to send message. Please try again.";
        return;
      }

      await this.sendSuccess();
    },
    async createTicket() {
      console.log("createTicket");
      if (!this.isNamePrePopulated) {
        console.log("isNamePrePopulated");
        if (!this.firstName || this.firstName?.length === 0) {
          this.uiStates.firstName = false;
          this.uiInvalidFeedback.firstName = "Please enter a first name.";
          return;
        }
        if (!this.lastName || this.lastName?.length === 0) {
          this.uiStates.lastName = false;
          this.uiInvalidFeedback.lastName = "Please enter a last name.";
          return;
        }

        const result = await this.setUserName();

        console.log("result", result);

        if (!result) {
          this.uiStates.form = false;
          this.uiInvalidFeedback.form = "There was an error sending your message. Please try again.";
          return;
        }
      }

      const createTicketRequest: CreateTicketRequest = {
        claimId: this.claimId,
        claimUserId: this.userId,
      };

      const result = await onCallCrmGateway<"createTicket">({
        functionName: "createTicket",
        data: {
          createTicketRequest,
          message: {
            body: this.message,
            subject: "",
            type: "email",
          },
        },
      });

      if (result.data.error || !result.data.data || result.data.exception) {
        this.uiStates.message = false;
        this.uiInvalidFeedback.message = "Failed to send message. Please try again.";
        return;
      }

      console.log("createTicket success");
      await this.sendSuccess();

      this.uiToggles.inProgress = false;

      await this.$router.push({
        query: {
          ticketId: result.data.data.id,
        },
      });

      this.$bvModal.hide("contactModal");

      window.location.reload();
    },
    async setUserName(): Promise<boolean> {
      console.log("setUserName");
      // get the user data service
      this.userDataService.setArtefact("firstName", this.firstName);
      this.userDataService.setArtefact("lastName", this.lastName);
      // set names
      this.userDataService.update();
      console.log("setUserName success");
      return true;
    },
    async sendSuccess() {
      await this.infoModalService.fire("info", {
        title: "Success",
        text: "Message has been sent!",
      });
    },
    resetState() {
      Object.keys(this.uiStates).forEach((key) => {
        this.uiStates[key] = null;
      });

      Object.keys(this.uiInvalidFeedback).forEach((key) => {
        this.uiInvalidFeedback[key] = "";
      });
    },

    async emailWorkspaceContactFunction() {
      const response = await onCallGateway<"emailWorkspaceContact">({
        functionName: "emailWorkspaceContact",
        data: {
          claimId: this.claimId,
          email: this.email ?? "",
          subject: this.subject,
          body: this.message,
        },
      });

      return response;
    },
  },
  async mounted() {
    this.userDataService = new UserDataService(this.userId);
  },
});
