
import BlockInfo from "@/components/shared/blocks/info.vue";
import BlockLabel from "@/components/shared/blocks/label.vue";
import BlockPadding from "@/components/shared/blocks/padding.vue";
import BlockInvalidFeedback from "@/components/shared/blocks/invalidfeedback.vue";
import { inputWrapperProps } from "@/helpers/ClaimsGate/blocks/inputProps";
import Vue from "vue";
import { Props } from "@/types";
export default Vue.extend({
  name: "BlockInputWrapper",
  components: {
    BlockInfo,
    BlockLabel,
    BlockPadding,
    BlockInvalidFeedback,
  },
  props: {
    ...(inputWrapperProps() as Props.BlockInputWrapper),
  },
});
