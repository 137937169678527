
import { Props } from "@/types";

import Vue from "vue";
import { themesComputed } from "@/state/helpers";
import { inputProps } from "@/helpers/ClaimsGate/blocks/inputProps";

import BlockCountryPicker from "@/components/shared/blocks/countryPicker/countryPicker.vue";
const inputPropsValue: Props.BlockInput = inputProps({
  extraProps: ["elementId"],
});
console.log("🚀 ~ file: country.vue:12 ~ inputPropsValue:", inputPropsValue);

export default Vue.extend({
  name: "BlockCountry",
  components: { BlockCountryPicker },
  props: {
    ...inputPropsValue,
    answer: {
      type: String,
      required: false,
    },
    storeAs: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      selected: false,
      newAnswer: "" as string,
      hover: false,
    };
  },
  watch: {
    answer: {
      handler: function (val) {
        if (val) {
          this.newAnswer = val;
        }
      },
    },
  },
  methods: {
    handleCountrySelected(country: { iso2: string }): void {
      console.log(country);

      if (country.iso2) {
        this.newAnswer = country.iso2;
        this.$emit("update:answer", this.newAnswer);
        this.$emit("update:state", null);
      }
    },
  },
  mounted() {
    if (this.answer) {
      this.newAnswer = this.answer;
    }
  },
  computed: {
    ...themesComputed,
    primaryStyles(): Record<string, string | number> {
      return this.theme?.primaryStyles ?? {};
    },
    size(): string {
      if (this.theme?.size?.length > 0) {
        return this.theme.size;
      } else {
        return "lg";
      }
    },
  },
});
