
import Vue from "vue";
export default Vue.extend({
  name: "ComplexQuestionModal",
  props: {
    title: {
      type: String,
      required: true,
    },
    subTitle: {
      type: String,
      required: true,
    },
    currentSectionIndex: {
      type: Number,
      required: true,
    },
    sections: {
      type: Number,
      required: true,
    },
    isProcessing: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    buttonText(): string {
      return this.isLastSection ? "Save" : "Next";
    },
    isLastSection(): boolean {
      return this.currentSectionIndex === this.sections - 1;
    },
  },
  methods: {
    close() {
      this.$bvModal.hide("complexQuestionModal");
    },
    previous() {
      this.$emit("previous");
    },
    next() {
      this.$emit("next");
    },
    save() {
      this.$emit("save");
    },
  },
});
