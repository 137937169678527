
// General
import Vue from "vue";

export default Vue.extend({
  name: "TrackSection",
  components: {},
  props: {
    title: {
      type: String,
    },
  },
  computed: {},
  data() {
    return {};
  },
  async mounted() {},
  methods: {},
});
