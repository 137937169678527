
import Vue from "vue";

import BlockIcon from "@/components/shared/blocks/iconBlock/icon.vue";
import BlockInputWrapper from "@/components/shared/blocks/inputwrapper.vue";

import { getProps } from "./props";

export default Vue.extend({
  name: "BlockIconGroup",
  components: {
    BlockIcon,
    BlockInputWrapper,
  },
  props: {
    ...getProps(),
  },
  data() {
    return {
      selectedIndex: null,
    };
  },

  methods: {
    selectIcon(index) {
      this.selectedIndex = index;
      this.$emit("update:answer", this.icons[index].iconName);

      console.log("selectIcon: ", this.icons[index]);

      this.$nextTick(() => {
        const selectedElement = this.$el.querySelector(".selected-icon");
        if (selectedElement) {
          selectedElement.scrollIntoView({ behavior: "smooth", block: "nearest", inline: "center" });
        }
      });
    },
  },

  mounted() {
    if (this.answer) {
      this.selectedIndex = this.icons.findIndex((icon) => icon.iconName === this.answer);

      if (this.selectedIndex === -1) {
        this.selectedIndex = 0;
      }
      this.selectIcon(this.selectedIndex);
    } else {
      this.selectedIndex = 0;
      this.selectIcon(this.selectedIndex);
    }
  },
});
