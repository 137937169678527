
import Vue, { VueConstructor } from "vue";
import { methods, getProps, getData, BlockClaimsGateVehicleInstance } from "./BlockClaimsGateVehicle";
import { Fragment } from "vue-frag";

// Components

import BlockButton from "@/components/shared/blocks/button.vue";

import BlockSingleSelect from "@/components/shared/blocks/singleselect.vue";
import BlockPadding from "@/components/shared/blocks/padding.vue";

import { computed, mappedComputed } from "./computed";
import { mounted } from "./mounted";

export default (Vue as VueConstructor<Vue>).extend({
  name: "BlockClaimsGateVehicle",
  components: {
    Fragment,
    BlockButton,

    BlockSingleSelect,
    BlockPadding,
  },
  props: { ...getProps() },

  computed: {
    ...mappedComputed(),
    aComputed(): string {
      return computed.aComputed(this as unknown as BlockClaimsGateVehicleInstance);
    },
  },
  data() {
    return { ...getData(this) };
  },

  watch: {
    padding: {
      handler(padding): void {
        for (const blockInput of Object.values(this.BlockInputs)) {
          blockInput.padding = padding;
        }
      },
      immediate: true,
    },
    invalidFeedback: {
      handler(invalidFeedback): void {
        this.setInvalidationFeedback(invalidFeedback);
      },
    },
  },

  methods: {
    async submitDropout() {
      return methods.submitDropout(this as unknown as BlockClaimsGateVehicleInstance);
    },

    resetVehicleSearch() {
      methods.resetVehicleSearch(this as unknown as BlockClaimsGateVehicleInstance);
    },

    displayHelpModal() {
      methods.displayHelpModal(this as unknown as BlockClaimsGateVehicleInstance);
    },

    displayDropout() {
      methods.displayDropout(this as unknown as BlockClaimsGateVehicleInstance);
    },

    notMyVehicle() {
      methods.notMyVehicle(this as unknown as BlockClaimsGateVehicleInstance);
    },

    setInvalidationFeedback(invalidFeedback: string): void {
      methods.setValidationInvalidFeedback(this as unknown as BlockClaimsGateVehicleInstance, invalidFeedback);
    },
    async searchVehicle(): Promise<void> {
      await methods.searchVehicle(this as unknown as BlockClaimsGateVehicleInstance);
    },

    async submitVehicleConfirmation(): Promise<void> {
      await methods.submitVehicleConfirmation(this as unknown as BlockClaimsGateVehicleInstance);
    },

    async submitKeeperSelect(): Promise<void> {
      await methods.submitKeeperSelect(this as unknown as BlockClaimsGateVehicleInstance);
    },
  },
  async mounted(): Promise<void> {
    await mounted(this as unknown as BlockClaimsGateVehicleInstance);
  },
});
