
import BlockPadding from "@/components/shared/blocks/padding.vue";
import { themesComputed } from "@/state/helpers";
import { textProps } from "@/helpers/ClaimsGate/blocks/textProps";
import { getUserHelper } from "@/helpers/ClaimsGate/UserHelper";
import { templateText } from "@/helpers/vue";
import { ClaimDataService, UserDataService } from "@/helpers/ClaimsGate/DataService";
import { FunnelsService } from "@claimsgate/core";
import { getFirebaseBackend } from "@/authUtils";
import Vue, { VueConstructor } from "vue";
import { Variable } from "@claimsgate/core-types";
import { mapGetters } from "vuex";
export default (Vue as VueConstructor<Vue>).extend({
  name: "BlockText",
  components: { BlockPadding },
  props: {
    ...textProps(),
    condition: {
      type: Object,
      required: false,
      default: () => {
        return {
          actual: {},
          values: [],
          value: "",
          type: "",
        };
      },
    },
    isBuilder: {
      type: Boolean,
    },
  },
  computed: {
    ...themesComputed,
    parsedStyle(): string {
      if (this.styles === "paragraph") {
        return "p";
      }
      return this.styles;
    },
    templated(): string {
      return templateText(this.text, this);
    },
    funnelId(): string {
      if (this.$route.params.funnelId?.includes("-")) {
        return this.$route.params.funnelId.split("-").pop();
      } else {
        return this.$route.params.funnelId;
      }
    },
    ...mapGetters({
      workspaceName: "navigation/getWorkspaceName",
      funnel: "form/getFunnelMeta",
    }),
    funnelTitle(): string {
      return this.funnel?.title;
    },
  },
  data() {
    return {
      userId: null as string,
      claimId: this.$route.params.claimId,
      userService: getUserHelper(),
      funnelsService: new FunnelsService(getFirebaseBackend().firestore()),
      funnelVariables: null as Array<Variable>,
      claimDataService: null as ClaimDataService,
      userDataService: null as UserDataService,
    };
  },

  async mounted(): Promise<void> {
    this.userId = this.userService.getUserId();
    this.userDataService = new UserDataService(this.userId);
    this.claimDataService = new ClaimDataService(this.userId, this.claimId);
    const { data: funnelVariables } = await this.funnelsService.getFunnelVariables(this.funnelId);
    this.funnelVariables = funnelVariables;
  },
});
