
import Vue from "vue";
import { PropOptions } from "vue/types/umd";
import { Fragment } from "vue-frag";
export default Vue.extend({
  name: "ComplexQuestionCards",
  components: {
    Fragment,
  },
  props: {
    answers: {
      type: Array,
      required: true,
    } as PropOptions<Object[]>,
    entityName: {
      type: String,
      required: true,
    } as PropOptions<string>,
    entityNames: {
      type: Array,
      required: true,
    } as PropOptions<string[]>,
    entityValidationStates: {
      type: Array,
      required: true,
    } as PropOptions<boolean[]>,
  },
  computed: {
    addText(): string {
      return `Add a ${this.entityName}`;
    },
  },
  methods: {
    getRoundingClass(index: number): string {
      if (this.answers?.length === 1) {
        return "";
      }
      if (index === 0) {
        return "rounded-top square-bottom";
      }
      if (index === this.answers?.length - 1) {
        return "square-top rounded-bottom";
      }
      return "rounded-0";
    },
    getValidationClass(index: number): string {
      const state = this.entityValidationStates?.[index];
      if (state === true) {
        return "border-success border-thick";
      }
      if (state === false) {
        return "border-danger border-thick";
      }
      return "";
    },
  },
});
