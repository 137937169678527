
import Vue from "vue";
import { methods, getProps, getData } from "./AltVehicleBlockInstance";

// Components
import BlockButton from "@/components/shared/blocks/button.vue";
import BlockInputWrapper from "@/components/shared/blocks/inputwrapper.vue";
import BlockDatePicker from "@/components/shared/blocks/datepicker.vue";
import { mappedComputed } from "./computed";
import { mounted } from "./mounted";
import BlockPadding from "@/components/shared/blocks/padding.vue";

export default Vue.extend({
  name: "BlockAltVehicle",
  components: {
    BlockDatePicker,
    BlockButton,
    BlockInputWrapper,
    BlockPadding,
  },
  props: { ...getProps() },

  computed: {
    ...mappedComputed(),
    vehicleTypeText() {
      if (this.vehicleType === "boat") {
        return "boat";
      } else if (this.vehicleType === "plane") {
        return "aircraft";
      } else {
        return "vehicle";
      }
    },
  },
  data() {
    return { ...getData(this) };
  },

  methods: {
    submit() {
      methods.submit(this);
    },

    notMyVehicle() {
      methods.notMyVehicle(this);
    },
  },
  async mounted(): Promise<void> {
    await mounted(this);
  },
  watch: {
    //padding: {
    //  immediate: true,
    //  handler(newVal) {
    //    Object.keys(this.BlockInputs).forEach((key) => {
    //      this.BlockInputs[key].padding = newVal;
    //    });
    //  },
    //},
  },
});
