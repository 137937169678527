
// General
import Vue from "vue";

import { getFirebaseBackend, createInitialOnAuthStateChanged } from "@/authUtils.js";
import * as Sentry from "@sentry/vue";

import ConnectionModal from "@/components/shared/connectionModal/connectionModal.vue";
// Functions
import { getClaimsGateDomain, onWorkspaceDomain, onWorkspaceBranded } from "@/helpers/ClaimsGate/RedirectHelper";

// Vue X
import {
  metaComputed,
  navigationComputed,
  permissionsComputed,
  themesComputed,
  authComputed,
  authMethods,
} from "@/state/helpers";

// Services
import { UserHelper } from "@/helpers/ClaimsGate/UserHelper";
import { SentryService } from "@/helpers/ClaimsGate/SentryService";
import { FunnelsService, WorkspaceService } from "@claimsgate/core";
import { Intercom, User } from "@claimsgate/core-types";
import { onCallGateway } from "@/helpers/ClaimsGate/onCallGateway";
export default Vue.extend({
  data() {
    return {
      userService: new UserHelper(),
      workspaceService: new WorkspaceService(getFirebaseBackend().firebase()),
      sentryService: new SentryService(),
      funnelsService: new FunnelsService(getFirebaseBackend().firestore()),
      loading: true,
      currentWorkspace: "", // ? not used atm - could be useful to track what workspace we are currently on
      currentlyOnWorkspaceBrandedPage: null as boolean,
      currentlyOnWorkspaceDomainPage: null as boolean,
    };
  },
  components: {
    ConnectionModal,
  },

  methods: {
    ...authMethods,
    tryParseJSON(text) {
      try {
        return JSON.parse(text);
        // eslint-disable-next-line no-empty
      } catch (e) {
        console.log(text);
        console.error(e);
        return null;
      }
    },

    reportTaggedSentryIssueInfoData(explanation: string, tag: string) {
      Sentry.withScope((scope) => {
        scope.setFingerprint(["{{ default }}", process.env.VUE_APP_RELEASE_NUMBER, explanation]);
        scope.setTag("userId", getFirebaseBackend().getAuthenticatedUser()?.uid);
        scope.setTag("explanation", explanation);
        Sentry.captureMessage(tag);
      });
      console.log(explanation, tag);
    },

    /** Attempts to figure out the workspaceId of the page we are currently on */
    async determineWorkspaceId(): Promise<string> {
      let workspaceId;

      // derive workspaceId from domain
      if (window.location.host !== getClaimsGateDomain()) {
        ({ data: workspaceId } = await this.workspaceService.getWorkspaceIdFromSubdomain(window.location.host));
      }

      // derive workspaceId from route or from VueX
      if (!workspaceId) {
        workspaceId =
          this.$route.params["workspaceId"] ??
          this.$route.query.workspaceId?.toString() ??
          this.navigationWorkspaceId ??
          this.workspaceId;
      }

      // derive workpsaceId from claimId/funnelId
      if (
        !workspaceId &&
        (window.location.pathname.includes("/track/") ||
          window.location.pathname.includes("/form/") ||
          window.location.pathname.includes("/builder"))
      ) {
        let funnelId = this.$route.params["funnelId"];

        // Check if the funnelId contains a slug
        if (funnelId?.includes("-")) {
          funnelId = funnelId.split("-").pop();
        }

        if (funnelId) {
          const funnelFromCache = this.$store.state.form.funnelMeta;
          if (funnelFromCache?.authorId && funnelFromCache.id === funnelId) {
            workspaceId = funnelFromCache.authorId;
          } else {
            const { data: funnel } = await this.funnelsService.getFunnelMeta(funnelId);
            this.$store.dispatch("form/setFunnelMeta", funnel);
            if (!funnel) {
              return "";
            } else {
              workspaceId = funnel.authorId;
            }
          }
        } else if (this.$route.params["claimId"]) {
          const claimData = await this.userService.getClaimData(this.$route.params["claimId"]);
          if (claimData?.workspacesWithClaimDataAccess) {
            workspaceId = claimData.workspacesWithClaimDataAccess[0];
          } else {
            return "";
          }
        } else {
          console.log(">>> Sad Times");
        }
      }

      return workspaceId;
    },

    /** Loads in the correct theming and navigation data for the provided workspaceId */
    async loadNavigationAndTheme(onWorkspaceBrandedPage: boolean, workspaceId: string): Promise<void> {
      // Set Navigation and Theme Vue X Modules
      if (onWorkspaceBrandedPage) {
        // Navigation Vue X
        // Fetch relevant data about the workspace
        const workspace = await this.$store.dispatch("navigation/setWorkspace", {
          workspaceId: workspaceId,
        });
        console.log(`[App.vue] calling setTheme`);
        // Theme Vue X
        await this.$store.dispatch("themes/loadTheme", {
          themeId: workspace?.themeSelectedId,
        });

        this.currentWorkspace = workspaceId;
        this.currentlyOnWorkspaceBrandedPage = true;
      } else {
        this.$store.dispatch("navigation/clearWorkspace");
        await this.$store.dispatch("themes/loadAllFonts");
        await this.$store.dispatch("themes/loadClaimsGateTheme");

        this.currentWorkspace = "";
        this.currentlyOnWorkspaceBrandedPage = false;
      }
    },

    async checkAuthToken(): Promise<string> {
      // check if the authToken is defined and whether it is still valid (it gets invalidated after 3600 seconds)
      if (!this.authToken?.token || this.authToken?.createdAt + 3300 <= Math.round(new Date().getTime() / 1000)) {
        const authToken = await this.generateAuthToken();
        return authToken;
      }

      return this.authToken.token;
    },

    async generateAuthToken(): Promise<string> {
      // generate auth token
      const { data: authToken } = await onCallGateway<"generateCustomToken">({ functionName: "generateCustomToken" });

      if (authToken) {
        this.$store.dispatch("auth/setAuthToken", {
          token: authToken,
          createdAt: Math.round(new Date().getTime() / 1000),
        });
        return authToken;
      }

      return "";
    },

    /** Checks if we are on the correct domain and if not redirects us to it */
    async resolveCorrectDomain(onWorkspaceDomainPage: boolean, workspaceId: string): Promise<void> {
      // ? we don not want domain to be changed on production preview channel
      const isRunningOnPreviewChannelOrLocalHost =
        window.location.host.includes("claimsgate-4cda5--preview") || window.location.host.includes("localhost");
      if (isRunningOnPreviewChannelOrLocalHost) {
        return;
      }

      // if we are moving onto a workspace branded page we need to check whether that workspace has a primary domain associated with it
      if (onWorkspaceDomainPage) {
        const { data: primaryWorkspaceDomain } = await this.workspaceService.getPrimaryWorkspaceDomain(workspaceId);

        if (primaryWorkspaceDomain && window.location.host !== primaryWorkspaceDomain) {
          const authToken = await this.checkAuthToken();

          let redirectURL: string;
          if (this.$route.query) {
            const queryString = Object.keys(this.$route.query)
              .map((key) => `${key}=${this.$route.query[key]}`)
              .join("&");

            redirectURL = `https://${primaryWorkspaceDomain}${this.$route.path}?${queryString}&authToken=${authToken}`;
          } else {
            redirectURL = `https://${primaryWorkspaceDomain}${this.$route.path}?authToken=${authToken}`;
          }

          console.log(">>> App.vue - redirecting to: ", redirectURL, " from: ", window.location.href);
          window.location.href = redirectURL;
        }
      }
      // if we are moving onto CG branded page we need to move to claimsgate.co.uk
      else if (!onWorkspaceDomainPage) {
        let claimsgateDomain = getClaimsGateDomain();

        if (claimsgateDomain && window.location.host !== claimsgateDomain) {
          const authToken = await this.checkAuthToken();
          const redirectURL = `https://${claimsgateDomain}${this.$route.path}?authToken=${authToken}`;
          console.log(">>> App.vue - redirecting to: ", redirectURL, " from: ", window.location.href);
          window.location.href = redirectURL;
        }
      }
    },

    /** Loads in the latest User Data */
    async loadLatestUserData(user: User) {
      if (user) {
        const _user = {
          firstName: user.firstName,
          lastName: user.lastName,
          addressLine1: user.addressLine1,
          city: user.city,
          postcode: user.postcode,
          phoneNumber: user.phoneNumber,
          email: user.email,
          workspaces: user?.workspaces ?? [],
          communicationPreferences: user.communicationPreferences,
        };
        this.$store.dispatch("permissions/setUserDocument", _user);
      }

      if (user?.workspaces?.length > 0) {
        await this.$store.dispatch("permissions/setUserWorkspaces", user.workspaces);
      }
    },

    async displayIntercom(routeName: string, user, userId: string) {
      const intercomDisplayRoutes = ["Builder", "Funnels", "Pages", "Workspace"];
      window.console.log("[int] inter com!", routeName);
      if (intercomDisplayRoutes.includes(routeName)) {
        if (window.intercom) {
          window.intercom.shutdown();

          if (user && userId) {
            const { email, firstName, lastName } = user;
            window.console.log("[int] penv", process.env.VUE_APP_INTERCOM_APPID);
            if (process.env.VUE_APP_INTERCOM_APPID) {
              const intercomOptions: Intercom.IntercomOptions = {
                user_id: userId,
                app_id: process.env.VUE_APP_INTERCOM_APPID,
              };

              // If the user has an email we will attach it to intercom
              if (email) {
                intercomOptions.email = email;
              }

              // If the user has a first and last name we will attach their name to intercom
              if (firstName && lastName) {
                intercomOptions.name = `${firstName} ${lastName}`;
              }

              window.intercom.boot(intercomOptions);
            }
          }
        }
      }
    },
  },

  async beforeMount() {
    createInitialOnAuthStateChanged();

    getFirebaseBackend()
      .firebaseAuth()
      .onAuthStateChanged(async (user) => {
        let localStorageCurrentUser = null;
        try {
          localStorageCurrentUser = this.tryParseJSON(localStorage.getItem("auth.currentUser")); // compatibility with auth.js JSON.stringify on null but can't expect it to always be set
          // eslint-disable-next-line no-empty
        } catch {}
        if (!user) {
          // Lets try to detect corrupted user sessions and prompt them to login again
          if (localStorageCurrentUser != null) {
            this.reportTaggedSentryIssueInfoData(
              `Local storage populated with data but onAuthStateChanged user is null, logging the user out: ${JSON.stringify(
                localStorageCurrentUser
              )}`,
              "CorruptUserSession"
            );
            this.$router.push({ name: "logout" });
          }
        } else {
          if (localStorageCurrentUser != null && localStorageCurrentUser?.claims?.aud !== undefined) {
            const authDomain = localStorageCurrentUser.claims.aud;
            if (authDomain !== process.env.VUE_APP_PROJECTId) {
              this.reportTaggedSentryIssueInfoData(
                `Detected user session from a differnt auth domain: ${authDomain} !== ${process.env.VUE_APP_PROJECTId}`,
                "CorruptUserSession"
              );
              this.$router.push({ name: "logout" });
            }
          }
        }
      });

    // fetch users workspaces and store in Vue X (important for navigation)
    const userId = this.tryParseJSON(localStorage.getItem("auth.currentUser"))?.uid;
    if (userId && this.userWorkspaces?.length === 0) {
      const userData = await this.userService.getUserData();

      const { data: workspaces } = await this.workspaceService.getWorkspaceLogos(userData?.workspaces);
      if (workspaces?.length > 0) {
        await this.$store.dispatch("permissions/setUserWorkspaces", workspaces);
      }
    }
  },
  async mounted() {
    this.loading = true;

    if (process.env.VUE_APP_RUNNING_FROM_EMULATOR !== "true" && process.env.VUE_APP_PROJECTId === "claimsgate-4cda5") {
      window.console.error = (...args) => {
        this.sentryService.captureException(args[0], args, this.$route);
      };
    }

    this.loading = false;
  },
  destroyed() {
    if (window.intercom) {
      window.intercom.shutdown();
    }
  },

  computed: {
    ...themesComputed,
    ...navigationComputed,
    ...permissionsComputed,
    ...metaComputed,
    ...authComputed,
    /**
     * Tracks the route name of the currently visited page
     * @returns {String}
     */
    routeName(): any {
      return this.tryParseJSON(JSON.stringify(this.$route.name));
    },
  },
  watch: {
    /**
     * Runs every time the current route has changed
     * Checks if the user is on the correct domain or needs to be redirected
     * Loads & sets the correct theme and navigaion for the page
     * Decides if intercom should be shown on select pages
     */
    routeName: async function (routeName: string) {
      console.log(">>> App.vue routeName watcher started");
      this.currentlyOnWorkspaceDomainPage = window.location.host !== getClaimsGateDomain();
      const onWorkspaceBrandedPage = onWorkspaceBranded(this.$route);
      const onWorkspaceDomainPage = onWorkspaceDomain(this.$route);

      // show loader
      if (
        onWorkspaceBrandedPage !== this.currentlyOnWorkspaceBrandedPage ||
        onWorkspaceDomainPage !== this.currentlyOnWorkspaceDomainPage
      ) {
        this.loading = true;
      }

      // determine current workspaceId
      let currentWorkspaceId;
      if (onWorkspaceBrandedPage && !this.currentlyOnWorkspaceBrandedPage) {
        currentWorkspaceId = await this.determineWorkspaceId();
      }

      // resolve correct domain and transfer the user if needed
      await this.resolveCorrectDomain(onWorkspaceDomainPage, currentWorkspaceId);

      // set homeSelectedId to the current workspace Id
      if (this.currentlyOnWorkspaceDomainPage && currentWorkspaceId) {
        this.$store.dispatch("permissions/setHomeSelectedWorkspace", currentWorkspaceId);
      }

      // load in the correct theme and navigation
      if (onWorkspaceBrandedPage !== this.currentlyOnWorkspaceBrandedPage) {
        await this.loadNavigationAndTheme(onWorkspaceBrandedPage, currentWorkspaceId);
      }

      // load in latest user data
      const userId = this.userService.getUserId();
      const user = await this.userService.getUserData();
      this.loadLatestUserData(user);

      this.loading = false;

      // Display intercom on specific Claims Gate pages'
      if (window.intercom) {
        window.intercom.shutdown();
      }

      this.displayIntercom(routeName, user, userId);
    },
  },
});
