export { next } from "./next";
import { getFirebaseBackend } from "@/authUtils";
import { ClaimDataService, UserDataService } from "@/helpers/ClaimsGate/DataService";
import { PageUtility } from "@/helpers/ClaimsGate/pages";
import { MutatorFunction } from "@/types";
import { getFunnelVariables, Mishcon, Quantum, SlaterGordon, Tlw } from "@claimsgate/core";
import {
  Block,
  Page,
  PushIdentityDocumentToMilbergSalesForceTask,
  PushToMilbergSalesForceTask,
  Variable,
} from "@claimsgate/core-types";
import { DateTime } from "luxon";
import {
  fireCarwebNotQualifiedEvent,
  fireCarwebQualifiedEvent,
  fireMotorSpecsNotQualifiedEvent,
  fireMotorSpecsQualifiedEvent,
} from "./sentry";
import { FormState } from "./BlockFormInstance";
import { onCallGateway } from "@/helpers/ClaimsGate/onCallGateway";
import { String } from "lodash";
import { flattenPageBlocks, getPageById, prepareNextPage } from "./mounted";
import { createTrail, handleNextPage } from "./next";
import { complete, view } from "@/helpers/vue";
import { buildFunnelParamSlug } from "@/helpers/ClaimsGate/utils/buildFunnelParamSlug";

export const methods = {
  fakeMethod,
};

function fakeMethod(state: FormState) {
  return true;
}

export async function stopProcessing(state: FormState) {
  const loaderName = "next";
  // Toggle loader

  state.$store.dispatch("form/setLoader", { name: loaderName, value: false });
}

/** Creates a task in the Task Runner to pushIdentityDocumentsToMilbergSalesForce the claimant to Milberg's Intake API after 7 days */
export async function createPushToMilbergSalesforceTask(state: FormState): Promise<boolean> {
  try {
    if (!state.claimId || !state.userId) {
      return false;
    }

    // Check if the configuration allows for clients to be pushed to Milberg's Salesforce
    const apiRef = state.db.collection("config").doc("apis");
    const apiSnapshot = await apiRef.get();
    const api = apiSnapshot.data() as {
      milbergSalesforce: boolean;
    };

    if (!api.milbergSalesforce) {
      console.log(">>> Not pushing a claimant to Milberg's Salesforce API");
      return false;
    }

    const currentDate = new Date();
    const dateToPush = new Date(currentDate.getTime() + 60 * 60000);

    const task: PushToMilbergSalesForceTask = {
      options: {
        userId: state.userId,
        claimId: state.claimId,
      },
      status: "scheduled",
      worker: "pushToMilbergSalesforce",
      performAt: dateToPush,
    };

    const taskRef = state.db.collection("tasks").doc();

    console.log(">>> Pushing a claimant to Milberg's Salesforce API", taskRef.id);
    await taskRef.set(task, { merge: true });

    return true;
  } catch (exception) {
    console.error(exception);
    return false;
  }
}

/** Creates a task in the Task Runner to push the claimant's identity documents to Milberg's Intake API **/
export async function createPushIdentityDocumentsToMilbergSalesforceTask(state: FormState): Promise<boolean> {
  try {
    if (!state.claimId || !state.userId) {
      return false;
    }

    // Check if the configuration allows for clients to be pushed to Milberg's Salesforce
    const apiRef = state.db.collection("config").doc("apis");
    const apiSnapshot = await apiRef.get();
    const api = apiSnapshot.data() as {
      milbergSalesforce: boolean;
    };

    if (!api.milbergSalesforce) {
      console.log(">>> Not pushing a claimant to Milberg's Salesforce API");
      return false;
    }

    const currentDate = new Date();
    const dateToPush = new Date(currentDate.getTime() + 60 * 60000);

    const task: PushIdentityDocumentToMilbergSalesForceTask = {
      options: {
        userId: state.userId,
        claimId: state.claimId,
      },
      status: "scheduled",
      worker: "pushIdentityDocumentsToMilbergSalesForce",
      performAt: dateToPush,
    };

    const taskRef = state.db.collection("tasks").doc();

    console.log(">>> Pushing a claimant to Milberg's Salesforce API", taskRef.id);
    await taskRef.set(task, { merge: true });

    return true;
  } catch (exception) {
    console.error(exception);
    return false;
  }
}

/** Fires the 'MotorSpecsNotQualifiedEvent' to Sentry if the page contains the 'BlockMotorSpecs' */
export async function tryFireMotorSpecsNotVehicleQualifiedEvent(state: FormState) {
  if (pageContainsMotorCheckBlock(state.page)) {
    await fireMotorSpecsNotQualifiedEvent(state);
  }
}

/** Fires the 'MotorSpecsQualifiedEvent' to Sentry if the page contains the 'BlockMotorSpecs' */
export function tryFireMotorSpecsQualifiedEvent(state: FormState) {
  if (pageContainsMotorCheckBlock(state.page)) {
    fireMotorSpecsQualifiedEvent(state);
  }
}

/** Fires the 'CarwebNotQualifiedEvent' to Sentry if the page contains the 'BlockCarweb' */
export async function tryFireCarwebNotQualifiedEvent(state: FormState) {
  if (pageContainsCarwebBlock(state.page)) {
    await fireCarwebNotQualifiedEvent(state);
  }
}

/** Fires the 'CarwebQualifiedEvent' to Sentry if the page contains the 'BlockCarweb' */
export function tryFireCarwebQualifiedEvent(state: FormState) {
  if (pageContainsCarwebBlock(state.page)) {
    fireCarwebQualifiedEvent(state);
  }
}

/** Checks if a given page contains the BlockMotorCheck */
export function pageContainsMotorCheckBlock(page: Page): boolean {
  let result: boolean = false;

  const mutator: MutatorFunction<void> = (block: Block) => {
    if (block.type === "BlockMotorSpecs") {
      result = true;
    }
  };

  PageUtility.traverseBlocksSync(mutator, page, "cols");

  return result;
}

/** Checks if a given page contains the BlockMotorCheck */
export function pageContainsCarwebBlock(page: Page): boolean {
  let result: boolean = false;

  const mutator: MutatorFunction<void> = (block: Block) => {
    if (block.type === "BlockCarweb") {
      result = true;
    }
  };

  PageUtility.traverseBlocksSync(mutator, page, "cols");

  return result;
}

/** Checks if a given page contains the BlockMotorCheck */
export function pageContainsContactDetailsBlock(page: Page): boolean {
  let result: boolean = false;

  const mutator: MutatorFunction<void> = (block: Block) => {
    if (block.type === "BlockContactDetails") {
      result = true;
    }
  };

  PageUtility.traverseBlocksSync(mutator, page, "cols");

  return result;
}

/** Runs a conditional to be ran on the Contact Details page for Qunatum Funnels */
export async function quantumContactDetailsConditional(
  page: Page,
  funnelId: string,

  claimDataService: ClaimDataService
) {
  const quantumFunnels = [
    Quantum.Funnels.DRUMMOND_MILLER_DIESEL_EMISSIONS_ID,
    Quantum.Funnels.LEFEVRES_DIESEL_EMISSIONS_ID,
    Quantum.Funnels.SLATER_GORDON_DIESEL_EMISSIONS_ID,
    Quantum.Funnels.THOMPSONS_SOLICITORS_DIESEL_EMISSIONS_ID,
    Quantum.Funnels.JONES_WHYTE_DIESEL_EMISSIONS_ID,
    Quantum.Funnels.GILSON_GRAY_DIESEL_EMISSIONS_ID,
    Quantum.Funnels.STAGING_DIESEL_EMISSIONS_ID,
  ];

  if (quantumFunnels.includes(funnelId) && pageContainsContactDetailsBlock(page)) {
    const qualifiedMakes = [
      "Citroen",
      "Vauxhall",
      "Fiat",
      "Jaguar",
      "Land Rover",
      "landrover",
      "rover",
      "Nissan",
      "Peugeot",
      "Renault",
      "Volvo",
      "Ford",
      "BMW",
      "Mini",
      "Hyundai",
      "Kia",
    ];

    const [funnelVariables, _getFunnelVariables] = await getFunnelVariables(getFirebaseBackend().firestore(), funnelId);

    const emCodeVariableId = funnelVariables.find((variable) => variable.field === "emCode")?.id;
    const makeVariableId = funnelVariables.find((variable) => variable.field === "make")?.id;

    const emCode = claimDataService.getArtefact(emCodeVariableId);
    const make = claimDataService.getArtefact(makeVariableId);

    // If the vehicle has an emCode of 7 (EA288) break the conditional return null
    if (emCode === 8) {
      return null;
    }

    // If emCode does not exist or is greater than 4 and the vehicle's make does not contain the word fiat
    // If the make is not a part of the qualifiedMakes list
    if (
      (emCode === null && !make.toLowerCase().includes("fiat")) ||
      (emCode === undefined && !make.toLowerCase().includes("fiat")) ||
      !qualifiedMakes.map((make) => make.toLocaleLowerCase()).includes(make.toLocaleLowerCase())
    ) {
      if (funnelId === Quantum.Funnels.SLATER_GORDON_DIESEL_EMISSIONS_ID) {
        return { pageId: Quantum.RejectionPages.SLATER_GORDON_REJECTION_PAGE_ID, funnelId };
      } else if (funnelId === Quantum.Funnels.THOMPSONS_SOLICITORS_DIESEL_EMISSIONS_ID) {
        return { pageId: Quantum.RejectionPages.THOMPSONS_SOLICITORS_REJECTION_PAGE_ID, funnelId };
      } else if (funnelId === Quantum.Funnels.LEFEVRES_DIESEL_EMISSIONS_ID) {
        return { pageId: Quantum.RejectionPages.LEFEVRES_REJECTION_PAGE_ID, funnelId };
      } else if (funnelId === Quantum.Funnels.DRUMMOND_MILLER_DIESEL_EMISSIONS_ID) {
        return { pageId: Quantum.RejectionPages.DRUMMOND_MILLER_REJECTION_PAGE_ID, funnelId };
      } else if (funnelId === Quantum.Funnels.JONES_WHYTE_DIESEL_EMISSIONS_ID) {
        return { pageId: Quantum.RejectionPages.JONES_WHYTE_REJECTION_PAGE_ID, funnelId };
      } else if (funnelId === Quantum.Funnels.GILSON_GRAY_DIESEL_EMISSIONS_ID) {
        return { pageId: Quantum.RejectionPages.GILSON_GRAY_REJECTION_PAGE_ID, funnelId };
      } else if (funnelId === Quantum.Funnels.STAGING_DIESEL_EMISSIONS_ID) {
        return { pageId: Quantum.RejectionPages.STAGING_REJECTION_PAGE_ID, funnelId };
      }
    }
  }

  return null;
}

export async function previous(state: FormState) {
  try {
    await startPrevious(state);

    const previousPageId = await calculatePreviousPageId(state);

    const [currentPageId, error] = await updateClaimStep(state, previousPageId);

    if (error || !currentPageId) {
      console.error(error);
      stopPrevious(state);
      return false;
    }

    await state.claimDataService.refresh();

    state.router.acceptNewRoute({ pageId: currentPageId, funnelId: state.funnelId });
    state.pageId = currentPageId;
    const { data: page } = await state.pageHelper.getPage(currentPageId, state.funnelId);
    /*let { pageId, funnelId } = */ state.router.getNextRoute();
    await prepareNextPage(state, page);
    state.page = flattenPageBlocks(page);
    view(state, true);
    const funnelId = buildFunnelParamSlug(state.funnelId, state.funnelSlug);
    await state.$router.push({
      name: "form",
      params: { claimId: state.claimId, pageId: state.pageId, funnelId: funnelId },
      query: state.$route.query.testClaim === null ? { testClaim: null } : {},
    });
    stopPrevious(state);

    return true;
  } catch (error) {
    console.error(error);
    stopPrevious(state);
    return false;
  }
}
async function stopPrevious(state: FormState) {
  state.$store.dispatch("form/setLoader", { name: "previous", value: false });
}
async function startPrevious(state: FormState) {
  state.$store.dispatch("form/setLoader", { name: "previous", value: true });
  state.isError = false;
  state.pageService.resetValidation(state.page);
}

async function calculatePreviousPageId(state: FormState): Promise<string> {
  const trail = state.claimDataService.getArtefact("trail");
  const lastPageTrailElement = trail.pop();
  const previousPageId = lastPageTrailElement.pageId;
  return previousPageId;
}

async function updateClaimStep(state: FormState, newPageId: string): Promise<[pageId: string, error: any]> {
  const { data: result } = await onCallGateway<"updateClaimStep">({
    functionName: "updateClaimStep",
    data: { claimId: state.claimId, isClaimant: true, workspaceId: state.navigationWorkspaceId, newPageId },
  });

  if (result?.error || !result.data) {
    return [null, result?.error ?? "Unknown error"];
  }

  return [result.data.currentPageId, null];
}

export async function englandWalesRejectionConditional(
  page: Page,
  funnelId: string,
  claimDataService: ClaimDataService
) {
  const milbergDpfFunnelId = "xIIzib4WMmdDg6Sa8R8B";
  const cgTestingFunnelId = "g8ACY3bcCLQ8RvDlfAXl";

  const funnels = [
    milbergDpfFunnelId, // milberg dpf
    cgTestingFunnelId, // cg testing /workspace/pWeVgS0Boh8RWqb8hYL1
  ];

  const addressPages = [
    "xY8OhRzBmlqjmglCBIQf", // milberg Name and Address Details
    "J4gXs0GcCc7SjhmD6bAM", // milberg Name and Address Details (2),
    "x9lU5s91Abj4UDG6TRjs", // cg testing rejection
    "ZOjcKN1cq1t2oRydXcEx", // milberg agreement
    "Uz4B6823bgttrsfqFDsJ", // milberg agreement (2)
  ];

  const milbergRejectionPageId = "JLDBtVatO9TzyRgxQgCe";
  const cgTestingRejectionPageId = "u7G7k91zf6LE6kaj8zrP";

  if (!funnels.includes(funnelId)) return undefined;

  if (!addressPages.includes(page.id)) return undefined;

  const [funnelVariables, _getFunnelVariables] = await getFunnelVariables(getFirebaseBackend().firestore(), funnelId);

  const postcodeVariable = funnelVariables.find((variable) => variable.field === "postcode")?.id;

  const postcode = claimDataService.getArtefact(postcodeVariable);
  console.log(">>>REJECTCONDITION valid page", postcode);

  if (!postcode) return undefined; // ! This is techincally impossible if submitting a name and address page, potentially needds to throw an error

  const isNotEnglandOrWales = assertIsNotEnglandOrWales(postcode);
  console.log(">>>REJECTCONDITION isNotEnglandOrWales", isNotEnglandOrWales);
  if (isNotEnglandOrWales) {
    const nextPageId = funnelId === milbergDpfFunnelId ? milbergRejectionPageId : cgTestingRejectionPageId;
    return { pageId: nextPageId, funnelId };
  }

  return undefined;
}

function assertIsNotEnglandOrWales(postcode: string): boolean {
  // Normalize postcode by converting to uppercase and trimming whitespace
  const normalizedPostcode = postcode.toUpperCase().trim();

  // Define postcode prefixes for Scotland and Northern Ireland
  const scotlandPrefixes = [
    "AB",
    "DD",
    "DG",
    "EH",
    "FK",
    "G",
    "HS",
    "IV",
    "KA",
    "KW",
    "KY",
    "ML",
    "PA",
    "PH",
    "TD",
    "ZE",
  ];
  const northernIrelandPrefix = "BT";

  // Check for Northern Ireland postcode
  if (normalizedPostcode.startsWith(northernIrelandPrefix)) {
    return true;
  }

  // Check for Scotland postcode
  for (const prefix of scotlandPrefixes) {
    if (normalizedPostcode.startsWith(prefix)) {
      return true;
    }
  }

  // If none of the conditions are met, the postcode is in England or Wales
  return false;
}

export async function runFunnelSpecificLogicOnMount(state: FormState) {
  if (state.funnelId === Mishcon.ONE_COIN_FUNNEL_ID) {
    const localesMap = {
      hindi: "hi",
      arabic: "ar",
      malay: "ml",
      id_malay: "ms",
    };
    const pageMap = {
      hi: "YZcGMy64aPSWY6qGFtp4",
      ar: "P8WdlLvJKAUYj8nzjuIy",
      ml: "KAAyqihebV7eFlJfXZNv",
      ms: "gIcIK5s2P6du0VopdD2D",
    };

    const language = state.claimDataService.getArtefact("language");

    if (state.page.isStart) {
      createTrail(state, {});

      if (language === null || !localesMap[language]) {
        localStorage.setItem("locale", "en");
        localStorage.setItem("testIntl", "false");

        return;
      }

      // Mark the current page as complete
      complete(state);

      const targetPageId = pageMap[localesMap[language]];

      await state.$store.dispatch("themes/setLanguage", localesMap[language]);

      localStorage.setItem("locale", localesMap[language]);
      localStorage.setItem("testIntl", "true");

      state.router.acceptNewRoute({ pageId: targetPageId, funnelId: state.funnelId });

      const page = await getPageById(state, targetPageId, state.funnelId);

      state.claimDataService.setArtefact("currentPageId", targetPageId);

      state.page = flattenPageBlocks(page);
      state.pageId = targetPageId;

      view(state);

      await state.claimDataService.update();

      state.$router.push({
        name: "form",
        params: { claimId: state.claimId, pageId: targetPageId, funnelId: state.funnelId },
        query: state.$route.query.testClaim === null ? { testClaim: null } : {},
      });
    } else {
      if (language === null || !localesMap[language]) {
        localStorage.setItem("locale", "en");
        localStorage.setItem("testIntl", "false");

        return;
      }

      await state.$store.dispatch("themes/setLanguage", localesMap[language]);
      localStorage.setItem("locale", localesMap[language]);
      localStorage.setItem("testIntl", "true");
    }
  }

  if (state.funnelId === SlaterGordon.Funnels.MISSOLD_CAR_FINANCE_FUNNEL_ID) {
    const vehicleDataSourceValue = state.claimDataService.getArtefact(SlaterGordon.Funnels.Variables.vehicleDataSource);
    console.log("VEHICLE DATA SOURCE", vehicleDataSourceValue);
    if (!vehicleDataSourceValue) {
      state.claimDataService.setArtefact(SlaterGordon.Funnels.Variables.vehicleDataSource, "Car");
    }
  }
}

interface TlwRejection {
  tlwRejectionTag: string;
  tlwRejectionReason: string;
  tlwRejectionDate: Date;
}

interface TlwClassification {
  tlwExpectedClassificationStatus: string;
  tlwExpectedClassificationReason: string;
  tlwExpectedClassificationDate: Date;
}

export async function tlwDelayedPixelInitalisation(state: FormState) {
  const tlwVehicleLookupPage = Tlw.Pages.DIESEL_VEHICLE_LOOKUP;
  const tlwComputePages = [tlwVehicleLookupPage];

  if (state.funnelId !== Tlw.Funnels.DIESEL_EMISSIONS_FUNNEL_ID) {
    return;
  }

  if (!tlwComputePages.includes(state.page.id)) {
    return;
  }

  state.pixelInitalisationService.initPixels(state.funnelMeta.authorId, state.funnelId, state.claimDataService);
}

export async function tlwComputeRejection(page: Page, funnelId: string, state: FormState): Promise<boolean> {
  const tlwFunnels: string[] = [Tlw.Funnels.DIESEL_EMISSIONS_FUNNEL_ID];
  const tlwQualificationQuestionsPage = Tlw.Pages.DIESEL_QUALIFICATION_QUESTIONS;
  const tlwContactDetailsPage = Tlw.Pages.DIESEL_CONTACT_DETAILS;
  const tlwNameAddressPage = Tlw.Pages.DIESEL_NAME_ADDRESS;
  const tlwComputePages = [tlwQualificationQuestionsPage, tlwContactDetailsPage, tlwNameAddressPage];

  let rejectionReason: TlwRejection;

  if (!tlwFunnels.includes(funnelId)) {
    return false;
  }

  if (!tlwComputePages.includes(page.id)) {
    return false;
  }

  const [funnelVariables, _getFunnelVariables] = await getFunnelVariables(getFirebaseBackend().firestore(), funnelId);

  console.log(">>> tlwComputeRejection");

  if (page.id === tlwQualificationQuestionsPage) {
    // ! Let's first calculate the classification of the claim
    const vehicleEligibleVariableId = funnelVariables.find((variable) => variable.field === "tlwVehicleEligible").id;
    const vehicleEligible = state.claimDataService.getArtefact(vehicleEligibleVariableId);

    // Classify the vehicle as TLW Revenue Share or Omni
    if (vehicleEligible === true) {
      let tlwClassification = {} as TlwClassification;
      const statedPurchasePriceVariableId = funnelVariables.find(
        (variable) => variable.field === "statedPurchasePrice"
      ).id;

      const statedPurchasePrice = state.claimDataService.getArtefact(statedPurchasePriceVariableId) as string;

      const keeperOwnershipPeriodInMonthsVariableId = funnelVariables.find(
        (variable) => variable.field === "keeperOwnershipPeriodInMonths"
      ).id;

      const keeperOwnershipPeriodInMonths = state.claimDataService.getArtefact(keeperOwnershipPeriodInMonthsVariableId);

      // ! Rounded Estimated Purchase Price
      const estimatedPurchasePriceVariableId = funnelVariables.find(
        (variable) => variable.field === "estimatedPurchasePrice"
      ).id;
      const estimatedPurchasePrice = state.claimDataService.getArtefact(estimatedPurchasePriceVariableId);

      // ! Confirmed Purchase Price
      const confirmedPurchasePriceVariableId = funnelVariables.find(
        (variable) => variable.field === "confirmedPurchasePrice"
      ).id;
      const confirmedPurchasePrice = state.claimDataService.getArtefact(confirmedPurchasePriceVariableId);

      // ! If the client has owned the vehicle for less than 12 months then they fall into the TLW Revenue Share bracket
      if (keeperOwnershipPeriodInMonths < 12) {
        tlwClassification = {
          tlwExpectedClassificationStatus: "TLW Revenue Share - Pending",
          tlwExpectedClassificationReason: "Ownership period is less than 12 months",
          tlwExpectedClassificationDate: new Date(),
        } as TlwClassification;

        await storeTlwClassification(state, tlwClassification, funnelVariables);

        // ! If the client has confirmed their vehicle purchase price as correct.
      } else if (estimatedPurchasePrice && confirmedPurchasePrice && confirmedPurchasePrice.includes("Yes")) {
        // ! If the client has confirmed their vehicle was acquired between £5,000 & £7,499 then the client falls into the lower Omni bracket
        if (estimatedPurchasePrice >= 5000 && estimatedPurchasePrice < 7500) {
          tlwClassification = {
            tlwExpectedClassificationStatus: "Omni - Pending",
            tlwExpectedClassificationReason:
              "Lower Bracket - Vehicle purchase price is between £5,000 and £7,499 and ownership period is greater than 12 months",
            tlwExpectedClassificationDate: new Date(),
          } as TlwClassification;

          await storeTlwClassification(state, tlwClassification, funnelVariables);
          // ! If the client has confirmed their vehicle was acquired for more than £7,500 then the client falls into the upper Omni bracket
        } else if (estimatedPurchasePrice && estimatedPurchasePrice >= 7500) {
          tlwClassification = {
            tlwExpectedClassificationStatus: "Omni - Pending",
            tlwExpectedClassificationReason:
              "Upper Bracket - Vehicle purchase price is above £7,500 and ownership period is greater than 12 months",
            tlwExpectedClassificationDate: new Date(),
          } as TlwClassification;

          await storeTlwClassification(state, tlwClassification, funnelVariables);
        } else {
          tlwClassification = {
            tlwExpectedClassificationStatus: "TLW Revenue Share - Pending",
            tlwExpectedClassificationReason: "Vehicle purchase price is below £5,000",
            tlwExpectedClassificationDate: new Date(),
          } as TlwClassification;

          await storeTlwClassification(state, tlwClassification, funnelVariables);
        }
      } else {
        if (statedPurchasePrice && ["£5,000 - £7,500", "Above £5,000"].includes(statedPurchasePrice)) {
          tlwClassification = {
            tlwExpectedClassificationStatus: "Omni - Pending",
            tlwExpectedClassificationReason:
              "Lower Bracket - Vehicle purchase price is between £5,000 and £7,499 and ownership period is greater than 12 months",
            tlwExpectedClassificationDate: new Date(),
          } as TlwClassification;

          await storeTlwClassification(state, tlwClassification, funnelVariables);
        } else if (
          statedPurchasePrice &&
          ["Above £10,000", "£7,500 - £10,000", "Above £7,500"].includes(statedPurchasePrice)
        ) {
          tlwClassification = {
            tlwExpectedClassificationStatus: "Omni - Pending",
            tlwExpectedClassificationReason:
              "Upper Bracket - Vehicle purchase price is above £7,500 and ownership period is greater than 12 months",
            tlwExpectedClassificationDate: new Date(),
          } as TlwClassification;

          await storeTlwClassification(state, tlwClassification, funnelVariables);
        } else {
          tlwClassification = {
            tlwExpectedClassificationStatus: "TLW Revenue Share - Pending",
            tlwExpectedClassificationReason: "Vehicle purchase price is below £5,000",
            tlwExpectedClassificationDate: new Date(),
          } as TlwClassification;

          await storeTlwClassification(state, tlwClassification, funnelVariables);
        }
      }
    }

    const validationDateQualificationQuestionsVariableId = funnelVariables.find(
      (variable) => variable.field === "tlwValidationDateQualificationQuestions"
    ).id;
    state.claimDataService.setArtefact(validationDateQualificationQuestionsVariableId, new Date());

    const financePurchaseContactConsentVariableId = funnelVariables.find(
      (variable) => variable.field === "financePurchaseContactConsent"
    ).id;

    const financePurchaseContactConsent = state.claimDataService.getArtefact(financePurchaseContactConsentVariableId);

    // ! If the vehicle was not eligible for the NOx Diesel Emissions Claim but they are eligible for the PCP Claim
    const purchaseMethodVariableId = funnelVariables.find((variable) => variable.field === "purchaseMethod").id;
    const purchaseMethod = state.claimDataService.getArtefact(purchaseMethodVariableId);

    if (vehicleEligible === false && purchaseMethod === "Finance") {
      const vehicleRejectionTagVariableId = funnelVariables.find(
        (variable) => variable.field === "tlwVehicleRejectionTag"
      ).id;
      const vehicleRejectionTag = state.claimDataService.getArtefact(vehicleRejectionTagVariableId);

      const vehicleRejectionReasonVariableId = funnelVariables.find(
        (variable) => variable.field === "tlwVehicleRejectionExplanation"
      ).id;
      const vehicleRejectionReason = state.claimDataService.getArtefact(vehicleRejectionReasonVariableId);

      rejectionReason = {
        tlwRejectionTag: vehicleRejectionTag,
        tlwRejectionReason: vehicleRejectionReason,
        tlwRejectionDate: new Date(),
      };

      await storeRejectionReason(state, rejectionReason, funnelVariables);

      // ! We will return false so that the routing will be handled by the Builder.
      return false;
    }

    if (vehicleEligible === false && financePurchaseContactConsent === "No") {
      const vehicleRejectionTagVariableId = funnelVariables.find(
        (variable) => variable.field === "tlwVehicleRejectionTag"
      ).id;
      const vehicleRejectionTag = state.claimDataService.getArtefact(vehicleRejectionTagVariableId);

      const vehicleRejectionReasonVariableId = funnelVariables.find(
        (variable) => variable.field === "tlwVehicleRejectionExplanation"
      ).id;
      const vehicleRejectionReason = state.claimDataService.getArtefact(vehicleRejectionReasonVariableId);

      rejectionReason = {
        tlwRejectionTag: vehicleRejectionTag,
        tlwRejectionReason: vehicleRejectionReason,
        tlwRejectionDate: new Date(),
      };

      await storeRejectionReason(state, rejectionReason, funnelVariables);

      return true;
    }
  }

  if (page.id === tlwContactDetailsPage) {
    console.log(">>> tlwComputeRejection pageContainsContactDetailsBlock");

    const validationDateContactDetailsVariableId = funnelVariables.find(
      (variable) => variable.field === "tlwValidationDateContactDetails"
    ).id;
    state.claimDataService.setArtefact(validationDateContactDetailsVariableId, new Date());

    const vehicleEligibleVariableId = funnelVariables.find((variable) => variable.field === "tlwVehicleEligible").id;
    const vehicleEligible = state.claimDataService.getArtefact(vehicleEligibleVariableId);

    if (vehicleEligible === false) {
      const vehicleRejectionTagVariableId = funnelVariables.find(
        (variable) => variable.field === "tlwVehicleRejectionTag"
      ).id;
      const vehicleRejectionTag = state.claimDataService.getArtefact(vehicleRejectionTagVariableId);

      const vehicleRejectionReasonVariableId = funnelVariables.find(
        (variable) => variable.field === "tlwVehicleRejectionExplanation"
      ).id;
      const vehicleRejectionReason = state.claimDataService.getArtefact(vehicleRejectionReasonVariableId);

      rejectionReason = {
        tlwRejectionTag: vehicleRejectionTag,
        tlwRejectionReason: vehicleRejectionReason,
        tlwRejectionDate: new Date(),
      };

      await storeRejectionReason(state, rejectionReason, funnelVariables);

      return true;
    }

    const vehicleAcquisitionLocationVariableId = funnelVariables.find(
      (variable) => variable.field === "vehicleAcquisitionLocation"
    ).id;

    const vehicleAcquisitionLocation = state.claimDataService.getArtefact(vehicleAcquisitionLocationVariableId);

    if (vehicleAcquisitionLocation !== "England / Wales") {
      rejectionReason = {
        tlwRejectionTag: "Vehicle Acquisition Location",
        tlwRejectionReason: "The vehicle must have been purchased in England or Wales to be eligible for the claim.",
        tlwRejectionDate: new Date(),
      };

      await storeRejectionReason(state, rejectionReason, funnelVariables);

      return true;
    }

    const hasInstructedAnotherLawFirmVariableId = funnelVariables.find(
      (variable) => variable.field === "hasInstructedAnotherLawFirm"
    ).id;

    const hasInstructedAnotherLawFirm = state.claimDataService.getArtefact(hasInstructedAnotherLawFirmVariableId);

    if (hasInstructedAnotherLawFirm === "Yes") {
      rejectionReason = {
        tlwRejectionTag: "Instructed Another Law Firm",
        tlwRejectionReason: "You must not have instructed another law firm to be eligible for the claim.",
        tlwRejectionDate: new Date(),
      };

      await storeRejectionReason(state, rejectionReason, funnelVariables);

      return true;
    }

    return false;
  }

  if (page.id === tlwNameAddressPage) {
    console.log(">>> tlwComputeRejection pageContainsNameAddressBlock");

    const validationDateNameAddressVariableId = funnelVariables.find(
      (variable) => variable.field === "tlwValidationDateNameAddress"
    ).id;
    console.log(">>> tlwComputeRejection validationDateNameAddressVariableId", validationDateNameAddressVariableId);
    state.claimDataService.setArtefact(validationDateNameAddressVariableId, new Date());

    //const jurisdictionVariableId = funnelVariables.find((variable) => variable.field === "jurisdiction").id;

    const jurisdiction = state.userDataService.getArtefact("jurisdiction");
    console.log(">>> tlwComputeRejection jurisdiction", jurisdiction);
    if (!["england", "wales"].includes(jurisdiction?.toLowerCase())) {
      rejectionReason = {
        tlwRejectionTag: "Resides Out of Jurisdiction",
        tlwRejectionReason: "You must live in England or Wales to be eligible for the claim.",
        tlwRejectionDate: new Date(),
      };

      await storeRejectionReason(state, rejectionReason, funnelVariables);

      return true;
    }
  }

  return undefined;
}

async function storeTlwClassification(
  state: FormState,
  tlwClassification: TlwClassification,
  funnelVariables: Variable[]
) {
  const hashedClassification = await state.variableService.hashData(tlwClassification, funnelVariables);

  for (const [key, value] of Object.entries(hashedClassification)) {
    state.claimDataService.setArtefact(key, value);
  }
}

async function storeRejectionReason(state: FormState, rejectionReason: TlwRejection, funnelVariables: Variable[]) {
  const hashedRejection = await state.variableService.hashData(rejectionReason, funnelVariables);

  for (const [key, value] of Object.entries(hashedRejection)) {
    state.claimDataService.setArtefact(key, value);
  }
}
