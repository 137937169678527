
import Vue, { VueConstructor } from "vue";
import { methods, getProps, getData, BlockMotorSpecsInstance } from "./BlockMotorSpecs";
import { Fragment } from "vue-frag";

// Components

import BlockButton from "@/components/shared/blocks/button.vue";

import BlockDatePicker from "@/components/shared/blocks/datepicker.vue";
import BlockSingleSelect from "@/components/shared/blocks/singleselect.vue";
import BlockPadding from "@/components/shared/blocks/padding.vue";

import { computed, mappedComputed } from "./computed";
import { mounted } from "./mounted";

export default (Vue as VueConstructor<Vue>).extend({
  name: "BlockMotorSpecs",
  components: {
    Fragment,
    BlockButton,
    BlockDatePicker,
    BlockSingleSelect,
    BlockPadding,
  },
  props: { ...getProps() },

  computed: {
    ...mappedComputed(),
    aComputed(): string {
      return computed.aComputed(this as unknown as BlockMotorSpecsInstance);
    },
  },
  data() {
    return { ...getData(this) };
  },

  watch: {
    padding: {
      handler(padding): void {
        for (const blockInput of Object.values(this.BlockInputs)) {
          blockInput.padding = padding;
        }
      },
      immediate: true,
    },
    invalidFeedback: {
      handler(invalidFeedback): void {
        this.setInvalidationFeedback(invalidFeedback);
      },
    },

    "BlockInputs.currentOwnerSingleSelect.answer": function (newValue) {
      // If the prop to allow manual keeper entry is set to false, this logic should not run.
      if (!this.allowManualKeeperEntry) {
        return;
      }

      if (newValue === "Yes") {
        this.$nextTick(() => {
          this.uiToggles.showKeeperStartQuestion = true;
        });
        this.$nextTick(() => {
          this.uiToggles.showKeeperEndQuestion = false;
        });
      } else if (newValue === "No") {
        this.$nextTick(() => {
          this.uiToggles.showKeeperStartQuestion = true;
        });

        this.$nextTick(() => {
          this.uiToggles.showKeeperEndQuestion = true;
        });
      }
    },

    "BlockInputs.keeperSelectSingleSelect.answer": function (newValue) {
      if (!this.allowManualKeeperEntry) {
        return;
      }

      if (newValue !== "Other") {
        this.$nextTick(() => {
          this.uiToggles.showKeeperStartQuestion = false;
          this.uiToggles.showKeeperEndQuestion = false;

          this.BlockInputs.keeperStartDatePicker.answer = "" as any;
          this.BlockInputs.keeperEndDatePicker.answer = "" as any;
          this.BlockInputs.currentOwnerSingleSelect.answer = null;

          this.BlockInputs.keeperStartDatePicker.invalidFeedback = null;
          this.BlockInputs.keeperEndDatePicker.invalidFeedback = null;
          this.BlockInputs.currentOwnerSingleSelect.invalidFeedback = null;

          this.BlockInputs.keeperStartDatePicker.state = null;
          this.BlockInputs.keeperEndDatePicker.state = null;
          this.BlockInputs.currentOwnerSingleSelect.state = null;
        });
      }
    },
  },

  methods: {
    displayHelpModal() {
      methods.displayHelpModal(this as unknown as BlockMotorSpecsInstance);
    },
    notMyVehicle() {
      methods.notMyVehicle(this as unknown as BlockMotorSpecsInstance);
    },

    setInvalidationFeedback(invalidFeedback: string): void {
      methods.setValidationInvalidFeedback(this as unknown as BlockMotorSpecsInstance, invalidFeedback);
    },
    async searchVehicle(): Promise<void> {
      await methods.searchVehicle(this as unknown as BlockMotorSpecsInstance);
    },

    async submitVehicleConfirmation(): Promise<void> {
      await methods.submitVehicleConfirmation(this as unknown as BlockMotorSpecsInstance);
    },

    async submitKeeperSelect(): Promise<void> {
      await methods.submitKeeperSelect(this as unknown as BlockMotorSpecsInstance);
    },

    async submitOwnershipDate(): Promise<void> {
      await methods.submitOwnershipDate(this as unknown as BlockMotorSpecsInstance);
    },
  },
  async mounted(): Promise<void> {
    await mounted(this as unknown as BlockMotorSpecsInstance);
  },
});
