
import { getData } from "./data";
import { getProps } from "./props";
import { computed } from "./computed";
import * as methods from "./methods";
import Vue from "vue";
import BlockInputWrapper from "../inputwrapper.vue";
import BlockInput from "../input.vue";
import BlockPadding from "../padding.vue";
import CardHeader from "@/components/shared/styleWrappers/cardHeader/cardHeader.vue";

export default Vue.extend({
  name: "BlockDataArray",
  components: {
    BlockInputWrapper,
    BlockInput,
    BlockPadding,
    CardHeader,
  },
  props: {
    ...getProps(),
  },
  data() {
    return {
      ...getData(this),
    };
  },
  computed: {
    componentList(): object {
      return computed.componentList(this);
    },
    buttonText(): string {
      return computed.buttonText(this);
    },
    addText(): string {
      return computed.addText(this);
    },
    validationClasses(): string {
      return computed.validationClasses(this);
    },
  },
  methods: {
    generateInitialBlocks(): object[] {
      return methods.generateInitialBlocks(this);
    },
    async validations(): Promise<boolean> {
      return methods.validations(this);
    },
    async computes(): Promise<void> {
      return methods.computes(this);
    },
    async saveEntity(index: number): Promise<void> {
      return methods.saveEntity(index, this);
    },
    removeEntity(index: number): void {
      return methods.removeEntity(index, this);
    },
    async addEntity(): Promise<void> {
      return methods.addEntity(this);
    },
    editEntity(index: number): void {
      return methods.editEntity(index, this);
    },
    roundingClass(index: number): string {
      return methods.roundingClass(index, this);
    },
    getValidationClasses(index: number): string {
      return methods.getValidationClasses(index, this);
    },
    getFieldId(field: string): string {
      return methods.getFieldId(field, this);
    },
    deleteIncompleteEntityAnswer(index: number): void {
      return methods.deleteIncompleteEntityAnswer(index, this);
    },
  },
  mounted() {
    if (this.answer) {
      console.log("DataArray mounted with answer", this.answer);
      this.inputAnswer = this.answer;
      this.inputGroups = [];
      this.inputAnswer.forEach(() => {
        this.inputGroups.push(this.generateInitialBlocks());
      });
    } else {
      this.inputGroups = [this.generateInitialBlocks()];
    }
  },

  watch: {
    mode() {
      this.inputGroups = [this.generateInitialBlocks()];
    },
  },
});
