
import Vue from "vue";
import { PropOptions } from "vue";
import BlockPadding from "@/components/shared/blocks/padding.vue";
export default Vue.extend({
  name: "Carousel",
  components: {
    BlockPadding,
  },
  props: {
    padding: {
      type: String,
      required: false,
      default: "10",
      description: "The padding above the block",
    } as PropOptions<string>,
  },
  data() {
    return {
      slide: 0,
      slideHeight: 730,
      texts: [
        {
          main: '"Very happy with the updates regarding the Milberg diesel emissions claim... Kept up to date at regular intervals regarding the progress of the claim..."',
          sub: "Patrick",
        },
        {
          main: '"Having uses Milberg for my diesel claim. They have made the claim very easy by requesting clearly what they need from me & regular updated."',
          sub: "Amar",
        },
        {
          main: "\"Seem very proffesional in my mercedes diesel claim have now sent documents for my claim so I'm hoping for a quick payment as feel dissatisfied that I bought a Bluetec mercedes running on ad blue and I've been lied too on emmissions.\"",
          sub: "Michael",
        },
        {
          main: '"Sara Ahmed the advisor I spoke to was really helpful and brilliant, I couldn\'t ask for a better experience. Thank you Milberg, you stand heads over and above all the others I have spoken to on the matter of emissions claims for my Mercedes Sprinter."',
          sub: "Victoria",
        },
        {
          main: '"Delighted with the progress and updates I’m receiving from Milberg London LLP regarding my emission claim against Mercedes Benz."',
          sub: "Roddy",
        },
      ],
    };
  },
  methods: {
    getSlideHeights(): Array<any> {
      const refKey = "trustSlide";
      const result = [];
      this.texts.forEach((item, index) => {
        result.push(this.$refs[`${refKey}${index}`][0]?.clientHeight);
      });
      return result;
    },
    onSlideEnd() {
      this.slideHeight = this.getSlideHeights()[this.slide] + 400;
    },
  },
});
