
import Vue from "vue";
export default Vue.extend({
  name: "FileIcon",
  props: {
    fileExtension: {
      type: String,
      required: true,
    },
    width: {
      type: String,
      default: "40",
    },
    height: {
      type: String,
      default: "40",
    },
  },
  methods: {
    getBadgeColor(): string {
      const extension = this.fileExtension.toLowerCase();
      switch (extension) {
        case "pdf":
          return "#D92D20";
        case "jpg":
        case "jpeg":
        case "png":
        case "gif":
        case "mp4":
        case "avi":
        case "mov":
        case "doc":
        case "docx":
          return "#155EEF";
        default:
          return "#7F56D9";
      }
    },

    getBadgeWidth(): string {
      return this.fileExtension.length > 3 ? "31" : "25";
    },

    getBadgeX(): string {
      return this.fileExtension.length > 3 ? "-3" : "-2";
    },

    getTextX(): string {
      return this.fileExtension.length > 3 ? "0" : "1";
    },
  },
});
