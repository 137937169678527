
import Vue from "vue";
export default Vue.extend({
  name: "BlockInfo",
  props: {
    info: {
      type: String,
      default: "",
    },
  },
});
