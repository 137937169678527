
import Vue from "vue";
import { getFirebaseBackend } from "@/authUtils";
import ModalBodyWrapper from "@/components/shared/wrappers/modalBodyWrapper.vue";

export default Vue.extend({
  components: {
    ModalBodyWrapper,
  },
  data() {
    return {
      isOffline: false,
      unsubscribe: null as (() => void) | null,
    };
  },
  computed: {
    buttonText(): string {
      return this.isOffline ? "Waiting for Connection..." : "Refresh";
    },
  },
  methods: {
    updateConnectionStatus(online: boolean) {
      this.isOffline = !online;
    },
    refreshPage() {
      window.location.reload();
    },
  },
  mounted() {
    const db = getFirebaseBackend().firestore();
    const docRef = db.collection("config").doc("apis");

    this.unsubscribe = docRef.onSnapshot(
      { includeMetadataChanges: true },
      (docSnapshot) => {
        const source = docSnapshot.metadata.fromCache ? "local" : "server";
        const isFirestoreConnected = source === "server";
        this.updateConnectionStatus(navigator.onLine || isFirestoreConnected);
      },
      (error) => {
        console.error("Error fetching data from Firestore:", error);
      }
    );

    window.addEventListener("online", () => this.updateConnectionStatus(true));
    window.addEventListener("offline", () => this.updateConnectionStatus(false));
  },
  beforeDestroy() {
    if (this.unsubscribe) {
      this.unsubscribe();
    }

    window.removeEventListener("online", () => this.updateConnectionStatus(true));
    window.removeEventListener("offline", () => this.updateConnectionStatus(false));
  },
  watch: {
    isOffline: {
      handler(offline) {
        if (offline) {
          this.$bvModal.show("connectionLostModal");
        }
      },
    },
  },
});
