
// Import Claims Gate classes
import Vue from "vue";
import { getProps, getData, methods, mappedComputed, BlockYotiInstance, computed } from "./BlockYotiInstance";
import LoadingBar from "@/components/shared/layout/loadingBar.vue";
import BlockFaq from "@/components/shared/blocks/faq/faq.vue";

import BlockPadding from "@/components/shared/blocks/padding.vue";
import BlockButton from "@/components/shared/blocks/button.vue";
import BlockLink from "@/components/shared/blocks/link.vue";
import BlockManualIdUpload from "@/components/shared/blocks/manualIdUpload/yotiManualIdUpload.vue";
import Alert from "@/components/claimant/track/alert/alert.vue";
import ContactForm from "@/components/claimant/track/contactForm/contactForm.vue";
import { Fragment } from "vue-frag";
import CardHeader from "@/components/shared/styleWrappers/cardHeader/cardHeader.vue";

export default Vue.extend({
  name: "BlockYoti",
  components: {
    LoadingBar,
    BlockPadding,
    Fragment,
    BlockButton,
    Alert,
    BlockFaq,
    ContactForm,
    CardHeader,
    BlockLink,
    BlockManualIdUpload,
  },
  props: {
    ...getProps(),
  },
  data() {
    return {
      ...getData(this),
    };
  },
  methods: {
    reloadPage() {
      return methods.reloadPage();
    },
    emitSubmit() {
      return methods.emitSubmit(this as BlockYotiInstance);
    },
  },
  computed: {
    ...mappedComputed(),
    isVerified(): boolean {
      return computed.isVerified(this as BlockYotiInstance);
    },
    showContactFaq(): boolean {
      return computed.showContactFaq(this as BlockYotiInstance);
    },
  },
  async mounted(): Promise<void> {
    methods.beforeDestroy(this as BlockYotiInstance);
    await methods.mounted(this as BlockYotiInstance);
  },
  beforeDestroy() {
    methods.beforeDestroy(this as BlockYotiInstance);
  },
});
