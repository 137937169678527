
import Vue, { VueConstructor } from "vue";
import { methods, getProps, getData, BlockCaravanInstance } from "./BlockCaravanBlock";
import { Fragment } from "vue-frag";

// Components
import BlockPadding from "@/components/shared/blocks/padding.vue";
import BlockButton from "@/components/shared/blocks/button.vue";
import BlockSingleSelect from "@/components/shared/blocks/singleselect.vue";
import BlockDatePicker from "@/components/shared/blocks/datepicker.vue";

import { computed, mappedComputed } from "./computed";
import { mounted } from "./mounted";

export default (Vue as VueConstructor<Vue>).extend({
  name: "BlockCaravan",
  components: {
    Fragment,
    BlockPadding,
    BlockButton,
    BlockSingleSelect,
    BlockDatePicker,
  },
  props: { ...getProps() },

  computed: {
    ...mappedComputed(),
    aComputed(): string {
      return computed.aComputed(this as unknown as BlockCaravanInstance);
    },
  },
  data() {
    return { ...getData(this) };
  },

  methods: {
    displayHelpModal() {
      methods.displayHelpModal(this as unknown as BlockCaravanInstance);
    },

    notMyVehicle() {
      methods.notMyVehicle(this as unknown as BlockCaravanInstance);
    },

    searchVehicle() {
      methods.checkCaravanType(this as unknown as BlockCaravanInstance);
    },

    submitVehicleConfirmation() {
      methods.submitVehicleConfirmation(this as unknown as BlockCaravanInstance);
    },

    submitKeeperSelect() {
      methods.submitKeeperSelect(this as unknown as BlockCaravanInstance);
    },
  },
  async mounted(): Promise<void> {
    await mounted(this as unknown as BlockCaravanInstance);
  },
});
