
import Vue from "vue";

// Types
import { Props } from "@/types";

// Blocks
import BlockPadding from "@/components/shared/blocks/padding.vue";

// Services
import { InfoModalService } from "@/components/shared/global/informationModal/InfoModalService";

export default Vue.extend({
  name: "BlockFaq",
  components: {
    BlockPadding,
  },
  props: {
    padding: {
      type: String,
      description: "The padding above the block",
      default: "10",
    } as Props.CGPropExtras<string>,
    faqQuestion: {
      type: String,
      description: "The frequently asked question to be displayed",
      default: "Your FAQ",
    } as Props.CGPropExtras<string>,
    faqAnswer: {
      type: String,
      description: "The answer",
      default: "Your answer",
    } as Props.CGPropExtras<string>,
    displayInACard: {
      type: Boolean,
      description: "If true, the faq will be displayed in a card",
      default: true,
    } as Props.CGPropExtras<boolean>,
    isHtmlAnswer: {
      type: Boolean,
      default: false,
    },
    useSlot: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      infoModalService: new InfoModalService(this.$infoModal),
      faqShowing: false,
    };
  },
  computed: {},

  methods: {
    toggleFaq(): void {
      this.faqShowing = !this.faqShowing;
    },

    removeWhiteSpace(text: string): string {
      return text.replace(/\s/g, "");
    },
  },
  async mounted() {},
  watch: {},
});
