
import Vue from "vue";
import { getData } from "./data";
import { getProps } from "./props";
import { computed } from "./computed";
import * as methods from "./methods";
import { Fragment } from "vue-frag";
import BlockDatePicker from "@/components/shared/blocks/datepicker.vue";
import BlockInputWrapper from "@/components/shared/blocks/inputwrapper.vue";
import BlockTextArea from "@/components/shared/blocks/text/textarea.vue";
//import BlockPadding from "@/components/shared/blocks/padding.vue";
import { themesComputed } from "@/state/helpers";

export default Vue.extend({
  name: "BlockNestedMultiSelect",
  components: {
    Fragment,
    BlockDatePicker,
    BlockInputWrapper,
    BlockTextArea,
    //BlockPadding,
  },
  props: {
    ...getProps(),
  },
  data() {
    return {
      ...getData(this),
    };
  },
  computed: {
    ...themesComputed,
    ...computed, // ! we can't unpack computed, they have to be defined the same way as methods
  },
  methods: {
    async validations(): Promise<boolean> {
      return methods.validations(this);
    },
    async computes(): Promise<any> {
      console.log("nested computes");
      return this.answers;
    },
    isSelected(option: string): boolean {
      return methods.isSelected(this, option);
    },
    getIndex(option: string): number {
      return methods.getIndex(this, option);
    },
    organiseSelected(): string[] {
      return methods.organiseSelected(this);
    },
    setUpData(): void {
      methods.setUpData(this);
    },
    toggleSelected(option: string): void {
      methods.toggleSelected(this, option);
    },
    parseExistingAnswer(): void {
      methods.parseExistingAnswer(this);
    },
  },
  mounted() {
    this.setUpData();
    this.parseExistingAnswer();
  },
  watch: {
    selected: function () {
      this.selectedOrganised = this.organiseSelected();
    },
  },
});
