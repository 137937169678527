
import Vue from "vue";
import { methods, getProps, getData, computed, mappedComputed, BlockPhoneInstance } from "./blockPhone";
import { mounted } from "./mounted";

import { Fragment } from "vue-frag";

import BlockInput from "@/components/shared/blocks/input.vue";
import PhoneNumberInput from "@/components/shared/blocks/user/phone/phoneNumberInput/phoneNumberInput.vue";
import RecoverAccount from "@/components/shared/auth/RecoverAccount.vue";

import { computes, validations } from "./helpers";

export default Vue.extend({
  name: "BlockPhone",
  components: { Fragment, BlockInput, RecoverAccount, PhoneNumberInput },
  props: { ...getProps() },
  computed: {
    ...mappedComputed(),
    blockState: {
      get(): boolean {
        return computed.blockState(this as any as BlockPhoneInstance);
      },
      set(blockState: boolean): void {
        computed.blockState(this as any as BlockPhoneInstance, blockState);
      },
    },
  },
  data() {
    return { ...getData(this) };
  },
  async mounted() {
    await mounted(this as any as BlockPhoneInstance);
  },
  methods: {
    async validations() {
      return validations(this as any as BlockPhoneInstance);
    },
    async computes() {
      return computes(this as any as BlockPhoneInstance);
    },

    async handleAccountRecovered() {
      return methods.handleAccountRecovered(this as any as BlockPhoneInstance);
    },
    phoneNumberInputChanged(
      phoneNumber: string,
      phoneNumberObject: {
        country: {
          dialCode: string;
          iso2: string;
          name: string;
        };

        countryCallingCode: string;
        countryCode: string;
        formatted: string;
        nationalNumber: string;
        number: string;
        valid: boolean;
      }
    ) {
      if (phoneNumberObject.valid) {
        this.internationalPhoneNumber = {
          number: phoneNumberObject.number,
          countryCode: phoneNumberObject.countryCode,
        };
        this.BlockInputs.phone.answer = phoneNumberObject.number;
      } else {
        this.internationalPhoneNumber = { number: "", countryCode: "" };
      }
    },
  },
});
