
import Vue from "vue";
import BlockText from "@/components/shared/blocks/text/text.vue";
import { titleProps } from "@/helpers/ClaimsGate/blocks/textProps";
export default Vue.extend({
  name: "BlockTitle",
  components: { BlockText },
  props: {
    condition: {
      type: Object,
      required: false,
      default: () => {
        return {
          actual: {},
          values: [],
          value: "",
          type: "",
        };
      },
    },
    ...titleProps(),
    isBuilder: {
      type: Boolean,
      default: false,
    },
  },
});
