
import { formComputed } from "@/state/helpers";
import { ProgressBar, ProgressBarSection } from "@claimsgate/core-types";
import Vue from "vue";
import ProgressElement from "./progressElement/progressElement.vue";
import { Fragment } from "vue-frag";
import { ComputedProgressBarSection } from "./ComputedProgressBarSection";
import CardHeader from "@/components/shared/styleWrappers/cardHeader/cardHeader.vue";
import { Props } from "@/types";
export default Vue.extend({
  name: "SectionsProgressBar",
  components: {
    ProgressElement,
    CardHeader,
    Fragment: Fragment as any,
  },
  props: {
    mode: {
      type: String,
      required: true,
      valid: ["sections", "pages"],
      default: "sections",
    } as Props.Str,

    headerTitle: {
      type: String,
      required: true,
      description: "The title for the CardHeader",
      default: "Claim Summary",
    } as Props.Str,
    headerSupportingText: {
      type: String,
      required: true,
      description: "The supporting text for the CardHeader",
      default: "A summary of your progress through the claim",
    } as Props.Str,
  },
  computed: {
    sections(): Array<ComputedProgressBarSection> {
      return this.progressBarData.sections.map((section, index) => {
        return {
          ...section,
          isComplete: index < this.currentPageSectionIndex,
          isCurrentSection: index === this.currentPageSectionIndex,
        };
      });
    },
    currentPageSectionIndex(): number {
      return this.progressBarData.sections.findIndex((section) => section.pages.includes(this.currentPageId));
    },
    currentPageSection(): ProgressBarSection {
      return this.progressBarData.sections[this.currentPageSectionIndex];
    },
    currentPageIndexInSection(): number {
      return this.currentPageSection.pages.findIndex((pageId) => pageId === this.currentPageId);
    },
    ...formComputed,
    progressBarData(): ProgressBar {
      return (
        this.funnelMetaCache?.progressBar ?? {
          sections: [],
        }
      );
    },
    /**Map the pages into an array of objects for the elements to use in pages mode */
    pagesOfCurrentSection(): Array<ComputedProgressBarSection> {
      const currentSectionPages = this.sections[this.currentPageSectionIndex]?.pages ?? [];

      const funnelPages: { title: string; id: string }[] = this.funnelMetaCache?.pages ?? [];

      // convert page into section
      return currentSectionPages.map((pageId: string) => {
        return {
          title: funnelPages.find((pageMeta: { id: string }) => pageMeta.id === pageId)?.title ?? "",
          description: undefined,
          pages: [pageId],
          isComplete: currentSectionPages.indexOf(pageId) < this.currentPageIndexInSection,
          isCurrentSection: pageId === this.currentPageId,
        };
      });
    },
  },
  watch: {
    "$route.params.pageId"(value) {
      this.currentPageId = value;
    },
  },
  data() {
    return {
      currentPageId: this.$route.params.pageId,
    };
  },
});
