
import Vue from "vue";
import {
  methods,
  getProps,
  getData,
  mappedComputed,
  BlockContactDetailsInstance,
  computed,
} from "./blockContactDetails";

import BlockEmail from "@/components/shared/blocks/user/email/email.vue";
import BlockPhone from "@/components/shared/blocks/user/phone/phone.vue";
import BlockConsent from "@/components/shared/blocks/user/consent/consent.vue";
import BlockPadding from "@/components/shared/blocks/padding.vue";
import BlockButton from "@/components/shared/blocks/button.vue";
import BlockCheckbox from "@/components/shared/blocks/checkbox.vue";
import BlockSimpleName from "@/components/shared/blocks/simpleName.vue";
export default Vue.extend({
  name: "BlockContactDetails",
  components: {
    BlockPhone,
    BlockEmail,
    BlockConsent,
    BlockButton,
    BlockPadding,
    BlockCheckbox,
    BlockSimpleName,
  },
  props: { ...getProps() },
  computed: {
    ...mappedComputed(),
    loaderState(): boolean {
      //@ts-ignore
      return computed.loaderState(this as any as BlockContactDetailsInstance, this.loaders.next);
    },
  },
  data() {
    return { ...getData(this) };
  },

  methods: {
    async validations() {
      return methods.validations(this as any as BlockContactDetailsInstance);
    },
    async computes() {
      return methods.computes(this as any as BlockContactDetailsInstance);
    },
    emitSubmit() {
      return methods.emitSubmit(this as any as BlockContactDetailsInstance);
    },
  },
  async mounted() {
    await methods.mounted(this as any as BlockContactDetailsInstance);
  },
});
