
import Vue from "vue";
import { Props } from "@/types";
import BlockInputWrapper from "@/components/shared/blocks/inputwrapper.vue";
import { themesComputed } from "@/state/helpers";
import { inputProps } from "@/helpers/ClaimsGate/blocks/inputProps";

const inputPropsValue: Props.BlockMultiSelect = inputProps({
  answerType: "array",
  extraProps: ["options", "other"],
});
export default Vue.extend({
  name: "BlockMultiselect",
  components: { BlockInputWrapper },
  props: {
    ...inputPropsValue,
  },
  data() {
    return {
      /**
       * Array of answers emitted by the component
       *  @type { Array } */
      newAnswer: [],
      /**
       * User custom input if Other enabled
       * @type { String }
       */
      otherAnswer: "",
      /**
       * Other option is currently selected
       */
      otherSelected: false,
      /**
       * Used to tick the Other radio box, when other is selected
       * @type { Array<'Other'>}
       */
      otherVModel: [],
    };
  },
  methods: {
    /** @type { (index: number) => void} */
    select(index) {
      this.$emit("update:state", null);

      if (this.other && this.options[index] === "Other") {
        // other has been selected, toggle show other box
        this.otherSelected = !this.otherSelected;

        // remove other answer if it has been deselected
        if (!this.otherSelected) {
          this.removeOtherAnswer();
          this.otherVModel = [];
        } else {
          // Ticks the other box
          this.otherVModel["Other"];
        }

        // re-add input answer - user is toggling Other
        if (this.otherAnswer?.length > 0 && this.otherSelected) {
          this.newAnswer.push(this.otherAnswer);
        }
      } else if (this.newAnswer.includes(this.options[index])) {
        // user has deselected option, remove it from answer
        this.newAnswer.splice(this.newAnswer.indexOf(this.options[index]), 1);
      } else {
        //add answer as long as it is not 'Other'
        this.newAnswer.push(this.options[index]);
      }
      this.$emit("update:answer", this.newAnswer);
    },
    /** @type { (index: number) => Boolean} */
    isSelected(index) {
      if (this.newAnswer.includes(this.options[index])) {
        return true;
      }
      //  else if (this.showOtherInput && this.options.length - 1 === index) {
      //   return true;
      // }
      else {
        return false;
      }
    },
    selectOther() {
      // other has been selected, toggle show other box
      this.$emit("update:state", null);
      this.otherSelected = !this.otherSelected;
      // remove other answer if it has been deselected
      if (!this.otherSelected) {
        this.removeOtherAnswer();
        this.otherVModel = [];
      } else {
        this.otherVModel = ["Other"];
      }

      // re-add exting input answer - user is toggling Other
      if (this.otherAnswer?.length > 0 && this.otherSelected) {
        this.newAnswer.push(this.otherAnswer);
      }
    },
    handleOtherAnswer() {
      console.log("MULTI handling", this.otherAnswer, this.newAnswer);
      this.removeOtherAnswer();

      if (this.otherAnswer?.length > 0) {
        this.newAnswer.push(this.otherAnswer);
        this.$emit("update:answer", this.newAnswer);
      }

      console.log("MULTI handling after", this.otherAnswer, this.newAnswer);
    },
    /** Remove any user input answer */
    removeOtherAnswer() {
      this.newAnswer.forEach((answer, index) => {
        if (!this.options.includes(answer)) {
          this.newAnswer.splice(index, 1);
          this.$emit("update:answer", this.newAnswer);
        }
      });
    },
  },
  mounted() {
    // @ts-ignore
    if (this.answer === "") {
      this.newAnswer = [];
      this.$emit("update:answer", []);
    } else if (Array.isArray(this.answer)) {
      this.newAnswer = this.answer;
    } else if (!Array.isArray(this.answer) && this.newAnswer.length > 0) {
      // Error in stored answer for this block
    }

    /**
     * Check if the answer array contains an element that is not in the options. If true, update otherAnswer to match loaded data
     */
    const otherAnswerPresent = (/** @type {string[]} */ options, /** @type {string[]} */ answer) => {
      let otherAnswerPresent = false;
      if (Array.isArray(answer)) {
        answer.forEach((answer) => {
          if (!options.includes(answer)) {
            otherAnswerPresent = true;
            this.otherAnswer = answer;
          }
        });
      }
      return otherAnswerPresent;
    };

    console.log("MULTI pre load answer", this.newAnswer);
    if (this.other && otherAnswerPresent(this.options, this.answer)) {
      this.otherSelected = true;
      this.otherVModel = ["Other"];
      // this.select(this.options.length - 1);
    }
    console.log("MULTI after", this.newAnswer);
  },
  computed: {
    ...themesComputed,
  },
});
