
// eslint-disable-next-line no-unused-vars
import { Props } from "@/types";
import BlockInputWrapper from "@/components/shared/blocks/inputwrapper.vue";
import Vue, { PropType } from "vue";
import { themesComputed } from "@/state/helpers";
import { inputProps } from "@/helpers/ClaimsGate/blocks/inputProps";
const inputPropsValue: Props.BlockInput = inputProps({
  extraProps: ["elementId", "placeholder"],
});
export default Vue.extend({
  name: "BlockNumber",
  components: { BlockInputWrapper },
  props: {
    ...inputPropsValue,
    answer: {
      type: [Number, String] as PropType<number>,
      required: false,
    },
    isCurrency: {
      type: Boolean,
      required: false,
      description: "If true, the input is formatted as a currency",
      default: false,
    } as Props.CGPropExtras<boolean>,
    step: {
      type: Number as PropType<number>,
      required: false,
      description: "The step value for the number input",
      default: 1,
    } as Props.CGPropExtras<number>,
  },
  data() {
    return {
      selected: false,
      newAnswer: 0 as number,
      hover: false,
    };
  },
  watch: {
    /**
     * Allow for the parent to communicate prop changes to value
     * @param  {string} answer
     * @return {void}
     */
    answer: {
      handler: function (val) {
        if (val) {
          this.newAnswer = val;
        }
      },
    },
  },
  methods: {
    handleInput(input: number): void {
      this.newAnswer = input;
      this.$emit("update:answer", this.newAnswer);
      this.$emit("update:state", null);
    },
  },
  mounted() {
    this.newAnswer = this.answer ?? 0;
  },
  computed: {
    ...themesComputed,

    /** Calculates the primary styles to be applied to the input */
    primaryStyles(): Record<string, string | number> {
      return this.theme?.primaryStyles ?? {};
    },
    /**
     * If theme from vuex has not been intitalized, default to large
     */
    size(): string {
      if (this.theme?.size?.length > 0) {
        return this.theme.size;
      } else {
        return "lg";
      }
    },
    /**
     * 'phoneNumber' is not a supported input type for b-form-input, but is useful for browser autocompletes
     *  Convert it to number for b-form-input
     */
    type(): string {
      return this.inputType === "phoneNumber" ? "text" : this.inputType;
    },
  },
});
