
import Vue, { VueConstructor } from "vue";
import { methods, getProps, getData, BlockCarwebInstance } from "./BlockCarweb";
import { Fragment } from "vue-frag";

// Components

import BlockButton from "@/components/shared/blocks/button.vue";
import BlockSingleSelect from "@/components/shared/blocks/singleselect.vue";
import BlockPadding from "@/components/shared/blocks/padding.vue";
import BlockSubtitle from "@/components/shared/blocks/text/subtitle.vue";
import BlockTitle from "@/components/shared/blocks/text/title.vue";
import BlockHtml from "@/components/shared/blocks/html.vue";
import BlockLoadingBar from "@/components/shared/layout/loadingBar.vue";

import { computed, mappedComputed } from "./computed";
import { mounted } from "./mounted";

export default (Vue as VueConstructor<Vue>).extend({
  name: "BlockCarweb",
  components: {
    Fragment,
    BlockButton,
    BlockSingleSelect,
    BlockPadding,
    BlockSubtitle,
    BlockTitle,
    BlockHtml,
    BlockLoadingBar,
  },
  props: { ...getProps() },

  computed: {
    ...mappedComputed(),
    aComputed(): string {
      return computed.aComputed(this as unknown as BlockCarwebInstance);
    },
  },
  data() {
    return { ...getData(this) };
  },

  watch: {
    padding: {
      handler(padding): void {
        for (const blockInput of Object.values(this.BlockInputs)) {
          blockInput.padding = padding;
        }
      },
      immediate: true,
    },
    invalidFeedback: {
      handler(invalidFeedback): void {
        this.setInvalidationFeedback(invalidFeedback);
      },
    },
  },

  methods: {
    async submitVehicleHistorySelect(): Promise<void> {
      await methods.submitVehicleHistorySelect(this as unknown as BlockCarwebInstance);
    },

    displayHelpModal(): void {
      methods.displayHelpModal(this as unknown as BlockCarwebInstance);
    },
    notMyVehicle(): void {
      methods.notMyVehicle(this as unknown as BlockCarwebInstance);
    },

    setInvalidationFeedback(invalidFeedback: string): void {
      methods.setValidationInvalidFeedback(this as unknown as BlockCarwebInstance, invalidFeedback);
    },
    async searchVehicle(): Promise<void> {
      await methods.searchVehicle(this as unknown as BlockCarwebInstance);
    },

    async submitKeeperSelect(): Promise<void> {
      await methods.submitKeeperSelect(this as unknown as BlockCarwebInstance);
    },
  },
  async mounted(): Promise<void> {
    await mounted(this as unknown as BlockCarwebInstance);
  },
});
