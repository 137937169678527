
import Vue from "vue";
import { Fragment } from "vue-frag";

import BlockInput from "@/components/shared/blocks/input.vue";
import BlockDropdown from "@/components/shared/blocks/dropdown.vue";
import BlockPadding from "@/components/shared/blocks/padding.vue";
import { BlockClaimAddressInstance, getData, getProps, mappedComputed, methods } from "./BlockClaimAddress";

export default Vue.extend({
  name: "BlockClaimAddress",
  components: { Fragment, BlockInput, BlockDropdown, BlockPadding },
  computed: {
    ...mappedComputed(),
    fake(): string {
      return this.fake;
    },
  },
  data() {
    return {
      ...getData(this),
    };
  },
  watch: {
    /** Propagate padding to the child components */
    padding: {
      handler() {
        Object.keys(this.BlockInputs).forEach((key) => {
          this.BlockInputs[key].padding = this.padding;
        });
      },
    },
  },
  methods: {
    async searchAddress(): Promise<void> {
      await methods.searchAddress(this as BlockClaimAddressInstance);
    },
    async validations(): Promise<boolean> {
      return methods.validations(this as BlockClaimAddressInstance);
    },
    async computes(): Promise<boolean> {
      return methods.computes(this as BlockClaimAddressInstance);
    },
  },
  props: {
    ...getProps(),
  },

  async mounted() {
    await methods.mounted(this as BlockClaimAddressInstance);
  },
});
