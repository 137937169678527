
import Vue from "vue";
// Components
import BlockCheckbox from "@/components/shared/blocks/checkbox.vue";
import BlockInvalidFeedback from "@/components/shared/blocks/invalidfeedback.vue";
import BlockPadding from "@/components/shared/blocks/padding.vue";
import BlockDraw from "@/components/shared/blocks/draw.vue";
import BlockHtml from "@/components/shared/blocks/html.vue";
import { getUserId } from "@/helpers/vue";
import { getProps } from "./props";
import { getData } from "./data";
import { submit } from "./methods";
import { mappedComputed, computed } from "./computed";
import { Fragment } from "vue-frag";
import { UserDataService } from "@/helpers/ClaimsGate/DataService";
export default Vue.extend({
  name: "BlockDsar",
  components: {
    Fragment,
    BlockCheckbox,
    BlockPadding,
    BlockDraw,
    BlockHtml,
    BlockInvalidFeedback,
  },
  props: {
    ...getProps(),
  },
  methods: {
    async submit() {
      submit(this);
    },
  },
  data() {
    return {
      ...getData(this),
    };
  },
  watch: {
    consentText() {
      this.BlockInputs.checkbox.text = this.consentText;
    },
  },
  computed: {
    ...mappedComputed(),
    loaHtml(): ReturnType<typeof computed.loaHtml> {
      return computed.loaHtml(this);
    },
    loaTemplateValues(): ReturnType<typeof computed.loaTemplateValues> {
      return computed.loaTemplateValues(this);
    },
    loaDisclaimerHtml(): ReturnType<typeof computed.loaDisclaimerHtml> {
      return computed.loaDisclaimerHtml(this);
    },
  },
  mounted() {
    const userId = getUserId();

    this.userId = userId;
    this.userDataService = new UserDataService(userId);
    this.user = this.userDataService.getCache();
  },
});
