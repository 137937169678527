
import Vue, { PropOptions } from "vue";

export default Vue.extend({
  name: "Alert",
  props: {
    mode: {
      type: String,
      default: "info",
    } as PropOptions<"info" | "success" | "warning" | "danger">,
    heading: {
      type: String,
      required: true,
    },
    supportingText: {
      type: String,
      required: true,
    },
    actions: {
      type: Array,
      default: () => [],
      required: false,
    } as PropOptions<Array<{ label: string; id: string }>>,
  },

  methods: {
    onActionClick(actionId: string) {
      this.$emit("actionSelected", actionId);
    },
  },

  computed: {
    borderColor(): string {
      return `border-${this.mode}-300`;
    },
    textColor(): string {
      return `text-${this.mode}-700`;
    },
    actionColor(): string {
      return `text-${this.mode}-700`;
    },

    bgColor(): string {
      return `bg-${this.mode}-25`;
    },
    iconColor(): string {
      return `text-${this.mode}-600`;
    },
    icon(): string {
      switch (this.mode) {
        case "info":
          return "info-circle";
        case "warning":
          return "exclamation-circle";
        case "danger":
          return "exclamation-triangle";
        default:
          return "info-circle";
      }
    },
  },
});
