
// General
import Vue, { PropOptions } from "vue";

// Vue X
import { themesComputed } from "@/state/helpers";

export default Vue.extend({
  name: "ModalWrapper",
  props: {
    /**
     * Boolean to specify whether theme should be applied
     */
    useTheme: { type: Boolean, default: false } as PropOptions<boolean>,
  },
  data() {
    return {};
  },
  computed: {
    ...themesComputed,
  },
  methods: {},
});
