
// Code Breakdown
import { methods, getProps, getData, computed, mappedComputed, AddressInstance } from "./AddressInstance";
import { mounted } from "./mounted";
import { blocks } from "./data";

// Components
import BlockInput from "@/components/shared/blocks/input.vue";
import BlockButton from "@/components/shared/blocks/button.vue";
import BlockDropdown from "@/components/shared/blocks/dropdown.vue";
import BlockLoadingBar from "@/components/shared/layout/loadingBar.vue";
import BlockPlaces from "@/components/claimant/form/places/places.vue";

import BlockSingleSelect from "@/components/shared/blocks/singleselect.vue";

//import BlockPadding from "@/components/shared/blocks/padding.vue";
import BlockDateOfBirth from "@/components/shared/blocks/user/dateofbirth.vue";
import BlockLink from "@/components/shared/blocks/link.vue";
import Vue from "vue";

export default Vue.extend({
  components: {
    BlockInput,
    BlockDropdown,
    BlockButton,
    BlockLoadingBar,
    BlockDateOfBirth,
    BlockSingleSelect,
    BlockLink,
    BlockPlaces,
    //BlockPadding,
  },
  props: { ...getProps() },
  data() {
    return { ...getData(this) };
  },
  methods: {
    emitSubmit() {
      return methods.emitSubmit(this as any as AddressInstance);
    },
    placeSelected(place: any) {
      return methods.placeSelected(this as any as AddressInstance, place);
    },

    searchAddressByPostcode() {
      return methods.searchAddressByPostcode(this as any as AddressInstance);
    },
    allFieldsDefined() {
      return methods.allFieldsDefined(this as any as AddressInstance);
    },
    setLookupMode() {
      return methods.setLookupMode(this as any as AddressInstance);
    },

    selectAddress(event: string) {
      return methods.selectAddress(this as any as AddressInstance, event);
    },
    setAndDisableCompletedFields() {
      return methods.disableCompletedFields(this as any as AddressInstance);
    },
    postcodeSearchSuccess() {
      return methods.postcodeSearchSuccess(this as any as AddressInstance);
    },
    setAddressManually() {
      return methods.setAddressManually(this as any as AddressInstance);
    },
    displayWholeLine() {
      return methods.displayWholeLine(this as any as AddressInstance);
    },
  },
  computed: {
    ...mappedComputed(),

    displayOtherInput: {
      get(): boolean {
        return computed.displayOtherInput(this as any as AddressInstance);
      },
    },

    loaderState(): boolean {
      return computed.loaderState(this as any as AddressInstance);
    },
    titleAnswer(): string {
      return computed.titleAnswer(this as any as AddressInstance);
    },
    otherAnswer(): string {
      return computed.otherAnswer(this as any as AddressInstance);
    },
    dateOfBirthAnswer(): any {
      return computed.dateOfBirthAnswer(this as any as AddressInstance);
    },
    userIsVerified(): boolean {
      return computed.userIsVerified(this as any as AddressInstance);
    },
  },
  watch: {
    dropdownSelectAnswer: function (newVal) {
      if (newVal) {
        methods.selectAddress(this as any as AddressInstance, newVal);
      }
    },
    padding: {
      handler: function (newPadding) {
        blocks.forEach((block) => {
          this.BlockInputs[block].padding = newPadding;
        });
      },
      immediate: true,
    },
    themeSize: function (newVal) {
      blocks.forEach((block) => {
        this.BlockInputs[block].size = newVal;
      });

      this.BlockInputs.postcodeSearchBlock.size = newVal;
    },
    titleAnswer: function (newVal, oldVal) {
      if (newVal && newVal !== oldVal) {
        if (newVal === "Other") {
          this.user.title = this.otherAnswer;
        } else {
          this.user.title = newVal;
        }
      }
    },
    otherAnswer: function (newVal, oldVal) {
      if (newVal && newVal !== oldVal && this.titleAnswer === "Other") {
        this.user.title = newVal;
      }
    },
    dateOfBirthAnswer: function (newVal, oldVal) {
      if (newVal && newVal !== oldVal) {
        this.user.dateOfBirth = this.dateService.parseDate(newVal);
      }
    },
  },
  async mounted() {
    await mounted(this as any as AddressInstance);
  },
});
