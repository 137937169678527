
import BlockPadding from "@/components/shared/blocks/padding.vue";
import Vue from "vue";
import { inputProps } from "@/helpers/ClaimsGate/blocks/inputProps";
// eslint-disable-next-line no-unused-vars
import { Props } from "@/types";
import { themesComputed } from "@/state/helpers";
// {field: 'nationalInsuranceNumber', group: 'user', id: 'IqZ7XgCCfqLU12JxyqLX', type: 'string'}

let inputPropsValue: Props.BlockInput = inputProps({ answerType: "string", extraProps: ["inputType"] });
delete inputPropsValue.label;
delete inputPropsValue.info;
delete inputPropsValue.required;
delete inputPropsValue.disabled;

export default Vue.extend({
  name: "BlockNationalInsurance",
  components: {
    BlockPadding,
  },
  props: {
    ...inputPropsValue,
  },
  data() {
    return {
      newAnswer: "",
      disabled: false,
    };
  },
  watch: {
    newAnswer: function () {
      this.$emit("update:answer", this.newAnswer.toUpperCase());
    },
  },
  mounted() {
    if (this.answer) {
      //@ts-ignore
      this.disabled = true;
    }
    this.newAnswer = this.answer ?? "";
  },
  computed: {
    ...themesComputed,
  },
});
