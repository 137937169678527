
// General
import Vue from "vue";

// Services
import { InfoModalService } from "@/components/shared/global/informationModal/InfoModalService";
import { SentryService } from "@/helpers/ClaimsGate/SentryService";

// Helpers
import { RedirectHelper } from "@/helpers/ClaimsGate/RedirectHelper";

// Vue X
import { themesComputed } from "@/state/helpers";
import { navigationComputed } from "@/state/helpers";

class LoadingError extends Error {
  constructor(message: string) {
    super(message);
    this.name = "Loading Error";
  }
}

export default Vue.extend({
  name: "BlockLoadingBar",
  data() {
    return {
      infoModalService: new InfoModalService(this.$infoModal),
      redirectHelper: new RedirectHelper(this.$router, this.$route),
      sentryService: new SentryService(),
      isLoading: false,
    };
  },
  props: {
    loading: {
      type: Boolean,
      required: false,
      default: false,
    },
    loadingText: {
      type: String,
      required: false,
      default: "Loading...",
    },
    useTimer: {
      type: Boolean,
      required: false,
      default: true,
    },
    alignCenter: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    ...themesComputed,
    ...navigationComputed,
  },
  methods: {
    /**
     * If loading times out, throw errors internally and to the user
     */
    async timedOut() {
      if (this.isLoading) {
        window.console.log("Loading timeout occurred");

        this.sentryService.captureException("loading", new LoadingError("Loading timeout occurred"), this.$route);

        // await this.infoModalService.fire(
        //   "error",
        //   {
        //     title: "Timed out",
        //     text: "Whoops, it looks like your request has timed out. Please try again.",
        //     icon: "error",
        //   },
        //   { preventFire: true }
        // );

        // await this.redirectHelper.redirect("Me", this.navigationWorkspaceId);
      } else {
        this.$destroy();
      }
    },
    async startTimer() {
      const timerPromise = new Promise((resolve) => {
        setTimeout(() => {
          resolve(true);
        }, 30000);
      });
      await timerPromise;
      if (this.isLoading) {
        await this.timedOut();
      } else {
        this.$destroy();
      }
    },
  },
  beforeDestroy() {
    this.isLoading = false;
  },
  async mounted() {
    this.isLoading = this.loading;
    if (this.useTimer) {
      this.startTimer();
    }
  },
  watch: {
    loading() {
      this.isLoading = this.loading;
    },
  },
});
