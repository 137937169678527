
import Vue from "vue";
import { getProps, getData, manualVehicleInstance, mappedComputed } from "./manualVehicleInstance";
import { continueClicked } from "./methods";
import { Fragment } from "vue-frag";
import BlockButton from "@/components/shared/blocks/button.vue";
import BlockPadding from "@/components/shared/blocks/padding.vue";
import BlockDatePicker from "@/components/shared/blocks/datepicker.vue";
import { getClaimDataService } from "@/helpers/vue";
export default Vue.extend({
  name: "BlockManualVehicle",
  components: {
    Fragment,
    BlockButton,
    BlockPadding,
    BlockDatePicker,
  },
  props: {
    ...getProps(),
  },
  computed: {
    ...mappedComputed(),
  },
  data() {
    return {
      ...getData(this),
    };
  },
  methods: {
    continueClicked(currentView: manualVehicleInstance["currentView"]) {
      continueClicked(this, currentView);
    },
  },
  async mounted() {
    this.userId = this.userService.getUserId();
    getClaimDataService<manualVehicleInstance>(this as any);

    let { data: funnelVariables } = await this.funnelsService.getFunnelVariables(this.funnelId);
    this.funnelVariables = funnelVariables;
  },
});
