
import Vue, { PropType } from "vue";
import { GreyTextColour, TextSize } from "../types";

export default Vue.extend({
  name: "HeadingContent",
  props: {
    heading: {
      type: String,
      required: true,
    },
    supportingText: {
      type: String,
      required: false,
    },
    hideSupportingText: {
      type: Boolean,
      default: false,
    },

    supportingTextClass: {
      required: false,
      type: String as PropType<GreyTextColour>,
      default: "text-grey-700" as GreyTextColour,
    },
    supportingBadge: {
      type: String,
      required: false,
    },
    supportingBadgeClass: {
      type: String,
      default: "text-grey-700",
    },
    headingTextClass: {
      required: false,
      type: String as PropType<GreyTextColour>,
      default: "text-grey-900" as GreyTextColour,
    },
    headingTextSize: {
      required: false,
      type: String as PropType<TextSize>,
      default: "text-lg" as TextSize,
    },
    supportingTextSize: {
      required: false,
      type: String as PropType<TextSize>,
      default: "text-sm" as TextSize,
    },
    headingMarginBottom: {
      required: false,
      type: String,
      default: "mb-1",
    },
  },
});
