
import { ComputedProgressBarSection } from "../ComputedProgressBarSection";
import Vue, { PropOptions } from "vue";
export default Vue.extend({
  name: "ProgressElement",
  props: {
    section: {
      type: Object,
      required: true,
    } as PropOptions<ComputedProgressBarSection>,
    currentPageId: {
      type: String,
      required: true,
    },
    isLastSection: {
      type: Boolean,
      required: true,
    },
    mode: {
      type: String,
      required: true,
    },
  },
  computed: {
    titleTextColour(): string {
      return this.isCurrentSection ? "text-primary-700" : "text-grey-700";
    },
    descriptionTextColour(): string {
      return this.isCurrentSection ? "text-primary-600" : "text-grey-600";
    },

    iconColour(): string {
      if (this.section.isComplete) {
        return "text-primary-50";
      } else if (this.isCurrentSection) {
        return "text-primary-50";
      } else {
        return "text-grey-50";
      }
    },
    iconInnerColour(): string {
      if (this.section.isComplete) {
        return "text-primary-600";
      } else if (this.isCurrentSection) {
        return "text-primary-600";
      } else {
        return "text-grey-200";
      }
    },
    textColor(): string {
      return this.isCurrentSection ? "text-primary-500" : "text-grey-700";
    },
    isCurrentSection(): boolean {
      return this.section.isCurrentSection;
    },

    isFirstPageOfSection(): boolean {
      return this.section.pages[0] === this.currentPageId;
    },
  },
});
