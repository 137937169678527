import { getClaim, getClaimsByFunnelId } from "@claimsgate/core";
import { manualVehicleInstance } from "./manualVehicleInstance";
import { getFirebaseBackend } from "@/authUtils";

export const methods = {};

export async function storeVehicleData(state: manualVehicleInstance) {
  if (!state.store.make || !state.store.model || !state.store.purchaseDate || !state.store.vrm) {
    return;
  }
  const store = {
    make: state.store.make,
    model: state.store.model,
    purchaseDate: state.store.purchaseDate,
    vehicleRegistration: state.store.vrm,
    enteredRegistration: state.store.vrm,
    otherMake: state.store.otherMake !== null ? state.store.otherMake : "",
  };

  const hashedAdditionalStores = await state.variablesService.hashData(store, state.funnelVariables);

  for (const [key, value] of Object.entries(hashedAdditionalStores)) {
    state.claimDataService.setArtefact(key, value);
  }
}

export function validateVRM(state: manualVehicleInstance): boolean {
  if (state.store.vrm) {
    const normalizedVRM = state.store.vrm.trim().toUpperCase().replace(/\s+/g, "");

    if (normalizedVRM.length === 0 || normalizedVRM.length > 7 || normalizedVRM === null) {
      state.uiState.vrm = false;
      state.invalidFeedbacks.vrm = "Please enter a valid registration number.";
      return false;
    } else {
      state.store.vrm = normalizedVRM;
      state.uiState.vrm = true;
      state.invalidFeedbacks.vrm = null;
      return true;
    }
  }
  state.uiState.vrm = false;
  state.invalidFeedbacks.vrm = "Please provide a registration number.";
  return false;
}

async function validateRegInput(state: manualVehicleInstance): Promise<boolean> {
  if (!validateVRM(state)) {
    console.log("validateVRM is false");
    return false;
  }

  /*
   * Debug, not sure if working correctly
   */
  if (!(await userHasClaimsForReg(state))) {
    console.log("userHasClaimsForReg is --", userHasClaimsForReg(state));
    state.$router.back();
    return false;
  }

  return true;
}

function validateMakeModel(state: manualVehicleInstance): boolean {
  //TODO:  state.store.model
  if (!state.store.make || state.store.make === null) {
    state.uiState.make = false;
    state.invalidFeedbacks.make = "Make not selected.";
    return false;
  }
  if (!state.store.model) {
    state.uiState.model = false;
    state.invalidFeedbacks.model = "Model Not Provided";
    return false;
  }

  const filteredMake = state.makeList.find((make) => make === state.store.otherMake);

  if (filteredMake) {
    state.uiState.otherMake = false;
    state.invalidFeedbacks.otherMake = "Make Already Exist in Make List, Please select from list";
    return false;
  }

  if (state.store.make === "Other" && !state.store.otherMake) {
    state.uiState.otherMake = false;
    state.invalidFeedbacks.otherMake = "Alternate Make not Provided";
    return false;
  }

  return true;
}
/**
 * needs to assert the purchase date is before jan 28th 2021.
 * Invalid feedback if not : "Sorry, the vehicle must have been purchased before January 28th 2021 to be eligible"
 */
function validatePurchaseDate(state: manualVehicleInstance): boolean {
  const ownershipDate = state.store.purchaseDate;
  if (ownershipDate === null) {
    return false;
  }
  if (new Date(ownershipDate) > new Date("2021-01-28")) {
    state.uiState.purchaseDate = false;
    state.invalidFeedbacks.purchaseDate =
      "Sorry, the vehicle must have been purchased before January 28th 2021 to be eligible";
    return false;
  }
  return true;
}

export async function continueClicked(state: manualVehicleInstance, currentView: manualVehicleInstance["currentView"]) {
  if (currentView === "regInput") {
    if (!(await validateRegInput(state))) {
      return;
    }
    state.currentView = "makeModel";
    return;
  }
  if (currentView === "makeModel") {
    if (!validateMakeModel(state)) return;
    state.currentView = "purchaseDate";
    return;
  }
  if (currentView === "purchaseDate") {
    if (!validatePurchaseDate(state)) return;

    // console.log("state of input values", state.store);
    storeVehicleData(state);

    await state.$store.dispatch("events/fire", { name: state.eventValues.next });
  }
}

export async function userHasClaimsForReg(
  state: manualVehicleInstance,
  options?: { ignoreRejectedClaims?: boolean }
): Promise<boolean> {
  const [claimsForUser, error] = await getClaimsByFunnelId(
    getFirebaseBackend().firestore(),
    state.userId,
    state.funnelId
  );

  if (state.claimDataService.getArtefact("claimStatus") === "testing") {
    return false;
  }

  if (error) {
    console.error("[manualVehicle/methods.ts] Failed to get claims for user", error);
  }

  const funnelVariables = state.funnelVariables;

  const vehicleRegistrationVariable = funnelVariables.find((variable) => variable.field === "vehicleRegistration");
  const enteredRegistrationVariable = funnelVariables.find((variable) => variable.field === "enteredRegistration");

  const existingClaim = claimsForUser.find((claim) => {
    if (claim.currentFunnelId !== state.funnelId) {
      return false;
    }

    if (claim.claimId === state.claimId) {
      return false;
    }

    if (claim.claimStatus === "paused" || claim.claimStatus === "deleted") {
      return false;
    }

    if (claim.clientClaimProgress === "rejected" && options.ignoreRejectedClaims) {
      return false;
    }

    return (
      claim?.[vehicleRegistrationVariable.id] === state.store.vrm ||
      claim?.[enteredRegistrationVariable.id] === state.store.vrm
    );
  });

  if (!existingClaim) {
    return false;
  }

  await state.infoModalService.fire("warning", {
    title: "You have already registered a claim for this vehicle",
    text: "It looks like you already have an active claim for this vehicle. Click resume to go to your existing claim.",
    cancelButtonText: "Cancel",
    confirmButtonText: "Resume",
    onConfirm: () => {
      if (state.navigationHideTrackPageClaimant) {
        getClaim(getFirebaseBackend().firestore(), state.userId, existingClaim.documentId).then(([_existingClaim]) => {
          // We just have to send the user to a specific url
          window.location.href =
            window.location.origin +
            `/form/${state.funnelId}/${_existingClaim.currentPageId}/${existingClaim.documentId}`;
        });
      } else {
        state.$router.push({ name: "Track", params: { claimId: existingClaim.documentId } }).then();
      }
    },
    onHide: () => {
      console.log("Closed");
    },
  });

  return true;
}
