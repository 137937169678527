
import Vue from "vue";
import { themesComputed } from "@/state/helpers";

export default Vue.extend({
  name: "BlockLabel",
  props: {
    label: {
      type: String,
      default: ""
    }
  },
  computed: {
    ...themesComputed
  }
});
