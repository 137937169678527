
// Vue
import Vue, { VueConstructor } from "vue";

// Component
import { methods, getProps, getData, BlockFinanceAgreementInstance } from "./BlockFinanceAgreement";
import { mappedComputed } from "./computed";
import { mounted } from "./mounted";

// Blocks
import BlockPadding from "@/components/shared/blocks/padding.vue";
import BlockSingleSelect from "@/components/shared/blocks/singleselect.vue";
import BlockMultiFileInput from "@/components/shared/blocks/multiFileInput.vue";
import BlockButton from "@/components/shared/blocks/button.vue";
import BlockDsar from "@/components/shared/blocks/dsar/dsar.vue";
export default (Vue as VueConstructor<Vue>).extend({
  name: "BlockFinanceAgreement",
  components: {
    BlockButton,

    BlockSingleSelect,
    BlockMultiFileInput,
    BlockPadding,
    BlockDsar,
  },
  props: { ...getProps() },

  computed: {
    ...mappedComputed(),
  },
  data() {
    return { ...getData(this) };
  },
  watch: {
    padding: {
      handler(padding): void {
        for (const blockInput of Object.values(this.BlockInputs)) {
          blockInput.padding = padding;
        }
      },
      immediate: true,
    },
    // invalidFeedback: {
    //   handler(invalidFeedback): void {
    //     this.setInvalidationFeedback(invalidFeedback);
    //   },
    // },
  },
  methods: {
    async submitFinanceAgreements(): Promise<void> {
      await methods.submitFinanceAgreements(this as unknown as BlockFinanceAgreementInstance);
    },
    async submitDeferUpload(): Promise<void> {
      await methods.submitDeferUpload(this as unknown as BlockFinanceAgreementInstance);
    },
  },
  async mounted(): Promise<void> {
    await mounted(this as unknown as BlockFinanceAgreementInstance);
  },
});
