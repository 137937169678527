
import Vue from "vue";
import { Fragment } from "vue-frag";
import BlockAddress from "@/components/shared/blocks/user/address/address.vue";
import BlockYoti from "@/components/shared/blocks/utility/yoti/yoti.vue";
//import BlockButton from "@/components/shared/blocks/button.vue";
//import BlockText from "@/components/shared/blocks/text.vue";
import BlockPadding from "@/components/shared/blocks/padding.vue";
import { BlockKycInstance, getData, getProps, mappedComputed, methods, computed } from "./BlockKycInstance";
import { emitSubmit } from "@/helpers/vue";
export default Vue.extend({
  name: "BlockKYC",
  components: { Fragment, BlockAddress, BlockYoti, BlockPadding },
  computed: {
    ...mappedComputed(),
    idFlowAllowsElectoralRoll(): boolean {
      return computed.idFlowAllowsElectoralRoll(this as BlockKycInstance);
    },
    idFlowAllowsYoti(): boolean {
      return computed.idFlowAllowsYoti(this as BlockKycInstance);
    },
  },
  data() {
    return {
      ...getData(this),
    };
  },
  props: {
    ...getProps(),
  },
  watch: {
    theme: {
      handler() {
        this.BlockInputs.address.size = (this.theme as any)?.size ?? "lg";
      },
      deep: true,
      immediate: true,
    },
    /** Propagate padding to the child components */
    padding: {
      handler() {
        Object.keys(this.BlockInputs).forEach((key) => {
          if (this.BlockInputs[key] && this.BlockInputs[key].padding) {
            this.BlockInputs[key].padding = this.padding;
          }
        });
      },
      immediate: true,
    },
  },
  methods: {
    async changeIdVerificationMethod(): Promise<void> {
      await methods.changeIdVerificationMethod(this as BlockKycInstance);
    },
    addressCompleted(): void {
      methods.addressCompleted(this as BlockKycInstance);
    },
    emitSubmit(): void {
      this.blockYotiVueKey++;
      emitSubmit(this);
    },
    async fireIdFailedModal(service: "Yoti" | "Electoral Roll"): Promise<void> {
      await methods.fireIdFailedModal(this as BlockKycInstance, service);
    },
  },
  async mounted() {
    await methods.mounted(this as BlockKycInstance);
  },
});
