
import Vue from "vue";
import { getProps } from "./props";
import { sgIconsObj } from "../../../../design/custom/plugins/icons/fontAwesome";
export default Vue.extend({
  name: "BlockIcon",
  props: {
    ...getProps(),
  },

  data() {
    return {
      hovered: false,
      sgIcons: sgIconsObj,
    };
  },
});
