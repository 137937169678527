
import Vue from "vue";
import BlockText from "@/components/shared/blocks/text/text.vue";
import { paragraphProps } from "@/helpers/ClaimsGate/blocks/textProps";
export default Vue.extend({
  name: "BlockParagraph",
  components: { BlockText },
  props: {
    condition: {
      type: Object,
      required: false,
      default: () => {
        return {
          actual: {},
          values: [],
          value: "",
          type: "",
        };
      },
    },
    ...paragraphProps(),
  },
});
